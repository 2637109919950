import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const contactRoutes = (page: "Customer" | "Organization" | "Institution") => [
  {
    path: "kontakt/erstellen",
    name: `${page}ContactDialogCreate`,
    component: () => import("@/components/admin/ContactSection/ContactDialog.vue"),
    props: { listRouteName: `${page}FormGeneral` },
  },
  {
    path: "kontakt/:id",
    name: `${page}ContactDialogEdit`,
    component: () => import("@/components/admin/ContactSection/ContactDialog.vue"),
    props: { listRouteName: `${page}FormGeneral` },
  },
]

const routes: Array<RouteRecordRaw> = [
  {
    path: "/kunden",
    name: "CustomerPage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["customer:list"],
    },
    component: () => import("./customer/CustomerPage.vue"),
    children: [
      {
        path: "erstellen",
        name: "CustomerDialog",
        component: () => import("./customer/CustomerDialog.vue"),
      },
    ],
  },
  {
    path: "/kunden/:cid",
    name: "CustomerView",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["customer:view"],
    },
    component: () => import("./customer/CustomerView.vue"),
    children: [
      {
        path: "allgemein",
        name: "CustomerFormGeneral",
        component: () => import("./customer/forms/GeneralForm.vue"),
        children: [...contactRoutes("Customer")],
      },
    ],
  },
  {
    path: "/traeger",
    name: "OrganizationPage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["organization:list"],
    },
    component: () => import("./organization/OrganizationPage.vue"),
    children: [
      {
        path: "erstellen",
        name: "OrganizationDialog",
        component: () => import("./organization/OrganizationDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["organization:create"],
        },
      },
    ],
  },
  {
    path: "/traeger/:cid/:oid",
    name: "OrganizationView",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["organization:view"],
    },
    component: () => import("./organization/OrganizationView.vue"),
    children: [
      {
        path: "allgemein",
        name: "OrganizationFormGeneral",
        component: () => import("./organization/forms/GeneralForm.vue"),
        children: [...contactRoutes("Organization")],
      },
    ],
  },
  {
    path: "/einrichtungen",
    name: "InstitutionPage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["institution:list"],
    },
    component: () => import("./institution/InstitutionPage.vue"),
    children: [
      {
        path: "erstellen",
        name: "InstitutionDialog",
        component: () => import("./institution/InstitutionDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["institution:create"],
        },
      },
    ],
  },
  {
    path: "/einrichtungen/:cid/:oid/:iid",
    name: "InstitutionView",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["institution:view"],
    },
    component: () => import("./institution/InstitutionView.vue"),
    children: [
      {
        path: "allgemein",
        name: "InstitutionFormGeneral",
        component: () => import("./institution/forms/GeneralForm.vue"),
        children: [...contactRoutes("Institution")],
      },
      {
        path: "zahlungsdaten",
        name: "InstitutionFormSepaConfigurationBanksForm",
        component: () => import("./institution/forms/bank/SepaConfigurationBanksForm.vue"),
        children: [
          {
            path: "bankverbindung/erstellen",
            name: "BankCreateDialog",
            props: { listRouteName: "InstitutionFormSepaConfigurationBanksForm" },
            component: () => import("./institution/forms/bank/BankDialog.vue"),
          },
          {
            path: "bankverbindung/:bankId",
            name: "BankEditDialog",
            props: { listRouteName: "InstitutionFormSepaConfigurationBanksForm" },
            component: () => import("./institution/forms/bank/BankDialog.vue"),
          },
        ],
      },
      {
        path: "oeffnung",
        name: "InstitutionFormOpeningHoursAndPlaces",
        component: () =>
          import("./institution/forms/OpeningHoursAndPlaces/OpeningHoursAndPlacesForm.vue"),
      },
      {
        path: "statistik",
        name: "InstitutionFormStatistics",
        component: () => import("./institution/forms/statistics/StatisticsForm.vue"),
      },
      {
        path: "bereiche",
        name: "InstitutionFormSection",
        component: () => import("./institution/forms/section/InstitutionSectionForm.vue"),
      },
    ],
  },
  {
    path: "/organisationseinheiten",
    name: "OrganizationUnitPage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["organization_unit:list"],
    },
    component: () => import("./organizationunit/OrganizationUnitPage.vue"),
    children: [
      {
        path: ":id",
        name: "OrganizationUnitDialogEdit",
        component: () => import("./organizationunit/OrganizationUnitEditDialog.vue"),
      },
      {
        path: "erstellen",
        name: "OrganizationUnitDialogCreate",
        component: () => import("./organizationunit/OrganizationUnitCreateDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["organization_unit:create"],
        },
      },
    ],
  },

  {
    path: "/benutzer",
    name: "UserPage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["user:list"],
    },
    component: () => import("./user/UserPage.vue"),
    children: [
      {
        path: "erstellen",
        name: "UserDialog",
        component: () => import("./user/UserDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["user:create"],
        },
      },
    ],
  },
  {
    path: "/benutzer/:id",
    name: "UserView",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["user:view"],
    },
    component: () => import("./user/UserView.vue"),
    children: [
      {
        path: "",
        name: "UserForm",
        component: () => import("./user/UserForm.vue"),
      },
    ],
  },
  {
    path: "/rechteverwaltung",
    name: "RolePage",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["role:list"],
    },
    component: () => import("./roles/RolePage.vue"),
    children: [
      {
        path: "erstellen",
        name: "RoleDialog",
        component: () => import("./roles/RoleDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["role:create"],
        },
      },
    ],
  },
  {
    path: "/rechteverwaltung/:id",
    name: "RoleView",
    meta: {
      requiresInstitutionContext: false,
      requiredRoles: <Permission[]>["role:view"],
    },
    component: () => import("./roles/RoleView.vue"),
    children: [
      {
        path: "",
        name: "RoleForm",
        component: () => import("./roles/RoleForm.vue"),
      },
    ],
  },
  {
    path: "/migration",
    name: "MigrationPage",
    meta: {
      requiresInstitutionContext: false,
      requiresAdmin: true,
    },
    component: () => import("./migration/MigrationPage.vue"),
  },
  {
    path: "/migration",
    name: "MigrationPage",
    meta: {
      requiresInstitutionContext: false,
      requiresAdmin: true,
    },
    component: () => import("./migration/MigrationPage.vue"),
  },
]

export default routes
