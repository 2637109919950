import {
  type TestContext,
  addMethod,
  boolean,
  date,
  object,
  ref,
  string,
  type StringSchema,
} from "yup"

import { isTimeAfterTime } from "./date"
import { isValidBic, isValidIban } from "./misc"

import type BaseCheckbox from "../components/common/BaseCheckbox.vue"
import type BaseInput from "../components/common/BaseInput.vue"
import type BaseNumberInput from "../components/common/BaseNumberInput.vue"
import type BaseParagraph from "../components/common/BaseParagraph.vue"
import type BaseSwitch from "../components/common/BaseSwitch.vue"
import type BaseTextarea from "../components/common/BaseTextarea.vue"
import type { ComposerTranslation } from "vue-i18n"

import { type Commune, type AccessLevel } from "@/graphql/types"
import { i18n } from "@/i18n"
import { useSessionStore } from "@/store/session"
import { type AdditionalAddressForm } from "@/types"

export type SchemaType = string | number | boolean
export type BaseSchema = Record<string, SchemaType | object>

export type SchemaComponent =
  | typeof BaseInput
  | typeof BaseTextarea
  | typeof BaseNumberInput
  | typeof BaseSwitch
  | typeof BaseCheckbox
  | typeof BaseParagraph

export type SchemaTypeToComponent<T extends SchemaType> = T extends string
  ? typeof BaseInput | typeof BaseTextarea
  : T extends number
    ? typeof BaseNumberInput
    : T extends boolean
      ? typeof BaseSwitch | typeof BaseCheckbox
      : never

// Matches only letters (A-Z, a-z), spaces, periods, and a range of Unicode characters for German accented letters (like Ä, Ö, Ü, and ß). Does not allow numbers.
export const alphaSpacePeriodRegex = /^[A-Za-z .\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/

// Matches only letters (A-Z, a-z), spaces, periods, and a range of Unicode characters for German accented letters (like Ä, Ö, Ü, and ß). It does allow numbers.
export const alphaNumericSpacePeriodRegex =
  /^[A-Za-z0-9 .\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/

export const installYupStringMethods = () => {
  addMethod<StringSchema>(string, "phone", function (this: StringSchema, $t: ComposerTranslation) {
    return this.matches(
      /^(?:(?:\+\(?(\d{1,3})\)?[\s-]?)?(?:\(?(\d{1,4})\)?[\s-]?)?(\d{1,4}[\s-]?){1,4})?$/,
      $t("validation.phone_invalid")
    ).max(15, $t("validation.phone_max_length"))
  })

  addMethod<StringSchema>(
    string,
    "matchesChars",
    function (this: StringSchema, regex: RegExp, allowedChars: string[], $t: ComposerTranslation) {
      return this.matches(
        regex,
        $t("validation.only_chars_allowed", { chars: allowedChars.join("") })
      )
    }
  )

  addMethod<StringSchema>(
    string,
    "noNumbers",
    function (this: StringSchema, $t: ComposerTranslation) {
      return this.matches(/^[^0-9]*$/, $t("validation.no_numbers_allowed"))
    }
  )

  addMethod<StringSchema>(
    string,
    "noSpecialChars",
    function (this: StringSchema, $t: ComposerTranslation) {
      return this.matches(
        /^[a-zA-Z0-9 .\-&,\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u1E9E]*$/,
        $t("validation.no_special_chars_allowed")
      )
    }
  )

  addMethod<StringSchema>(
    string,
    "password",
    function (this: StringSchema, $t: ComposerTranslation) {
      return this.min(8, $t("validation.password.length"))
        .matches(/[A-Z]/, $t("validation.password.upper_case"))
        .matches(/[a-z]/, $t("validation.password.lower_case"))
        .matches(/\d/, $t("validation.password.digit"))
        .matches(/[!@#$%^&*(),.?":{}|<>]/, $t("validation.password.special_char"))
    }
  )
  addMethod<StringSchema>(string, "iban", function (this: StringSchema, $t: ComposerTranslation) {
    return this.test("iban", $t("validation.invalid_iban"), function (value: string | undefined) {
      return value === "" || value === undefined || isValidIban(value)
    })
  })
  addMethod<StringSchema>(
    string,
    "zipCode",
    function (this: StringSchema, $t: ComposerTranslation) {
      return this.test(
        "is-min-zipcode",
        $t("form_validations.zipCode_min"),
        function (value: string | undefined) {
          const number = value && value.replace(/\D/g, "")
          return !(number && number.length < 4)
        }
      )
    }
  )
  addMethod<StringSchema>(string, "bic", function (this: StringSchema, $t: ComposerTranslation) {
    return this.test("bic", $t("validation.invalid_bic"), function (value: string | undefined) {
      return !value || isValidBic(value)
    })
  })
}

export const useInstitutionSchema = ($t: ComposerTranslation) => {
  const sessionStore = useSessionStore()
  return string().when([], {
    is: () => sessionStore.isUserOrganizationOrUnitOrHigher,
    then: (schema) => schema.required($t("validation.institution_required")),
  })
}

export const useSimpleAddressSchema = () => ({
  city: string().notRequired(),
  country: string().notRequired(),
  district: string().notRequired(),
  street: string().notRequired(),
  zipCode: string().notRequired(),
})

export const useEmailAddressSchema = ($t: ComposerTranslation) =>
  string().trim().email($t("validation.email_invalid"))

export const usePasswordSchema = ($t: ComposerTranslation) => ({
  password: string().required($t("validation.password.required")).password($t),
  confirmPassword: string().oneOf([ref("password")], $t("validation.password.not_matching")),
})

const minUsernameLength = 3
export const useUserSchema = ($t: ComposerTranslation) => ({
  salutation: string().required($t("admin.benutzer.validation.salutation_required")),
  firstname: string().trim().required($t("validation.firstname_required")),
  lastname: string().trim().required($t("validation.lastname_required")),
  username: string()
    .trim()
    .required($t("validation.username_required"))
    .min(minUsernameLength, $t("validation.username_min_length", { min: minUsernameLength })),
  email: useEmailAddressSchema($t).required($t("validation.email_required")),
  phone: string().phone($t).notRequired(),
})

export const useDateSchema = ($t: ComposerTranslation) =>
  date().typeError($t("validation.invalid_date"))

export const useBirthdaySchema = ($t: ComposerTranslation, requiredFlag = true) => {
  const schema = useDateSchema($t).max(new Date(), $t("validation.birthday_not_in_past"))
  if (!requiredFlag) return schema
  return schema.required($t("validation.birthday_required"))
}

export type PasswordSchema = {
  password: string
  confirmPassword: string
}

export type BaseUserSchema = {
  firstname: string
  lastname: string
  username: string
  email: string
  salutation: string
} & PasswordSchema

export type UserAccessLevelSchema = {
  accessLevel: AccessLevel
  customerId?: string
  organizationId?: string
  organizationUnitId?: string
  institutionId?: string
  roleId: string
}

export const useInitialUserSchema = ($t: ComposerTranslation) => ({
  createInitialUser: boolean(),
  user: object().when("createInitialUser", ([createInitialUser], schema) =>
    createInitialUser
      ? schema.shape({
          ...useUserSchema($t),
          ...usePasswordSchema($t),
          ...{ roleId: string().required($t("admin.benutzer.validation.role_required")) },
        })
      : schema
  ),
})

export type InitialUserSchema =
  | {
      createInitialUser: false
      user?: BaseUserSchema & {
        roleId: string
      }
    }
  | {
      createInitialUser: true
      user: BaseUserSchema & {
        roleId: string
      }
    }

export const useCommuneSchema = ($t: ComposerTranslation) => ({
  name: string().trim().required($t("stammdaten.gemeinden.validation.name_required")),
  communeKey: string()
    .trim()
    .required($t("stammdaten.gemeinden.validation.gemeindeschluessel_required")),
  contactPerson: object({
    name: string().notRequired(),
  }),
  address: object({
    street: string().trim().notRequired(),
    zipCode: string().trim().zipCode($t).notRequired(),
    district: string().trim().noNumbers($t).notRequired(),
    city: string().trim().noNumbers($t).notRequired(),
  }),
  additionalAddress: object({
    0: string(),
    1: string(),
  }),
  phone: string().phone($t).notRequired(),
  email: useEmailAddressSchema($t).notRequired(),
})

export const useInitialDomesticCommuneSchema = ($t: ComposerTranslation) => ({
  createInitialDomesticCommune: boolean(),
  commune: object().when(
    "createInitialDomesticCommune",
    ([createInitialDomesticCommune], schema) =>
      createInitialDomesticCommune
        ? schema.shape({
            ...useCommuneSchema($t),
          })
        : schema
  ),
})

export type InitialDomesticCommuneSchema =
  | {
      createInitialDomesticCommune: false
      commune?: Omit<Commune, "additionalAddress"> & AdditionalAddressForm
    }
  | {
      createInitialDomesticCommune: true
      commune: Omit<Commune, "additionalAddress"> & AdditionalAddressForm
    }

export const requiredIfProvided = (fieldForValidation: string, message: string) => ({
  name: "isRequired",
  message,
  test: (value: string | undefined, context: TestContext): boolean => {
    const relatedField = context.parent[fieldForValidation]
    return !!value || !relatedField
  },
})

export const timeOrderTest = (
  fieldForValidation: string,
  message: string,
  dayName: string,
  isAfter = false
) => ({
  name: isAfter ? "isAfter" : "isBefore",
  message: i18n.global.t(message, { dayName: i18n.global.t(`weekdays.${dayName}`) }),
  test: (value: string | undefined, context: TestContext) => {
    return isAfter
      ? isTimeAfterTime(value, context.parent[fieldForValidation])
      : isTimeAfterTime(context.parent[fieldForValidation], value)
  },
})
