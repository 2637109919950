import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/mitarbeiter",
    name: "EmployeePage",
    component: () => import("./employees/EmployeePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["employee:list"],
    },
    children: [
      {
        path: "erstellen",
        name: "EmployeeDialog",
        component: () => import("./employees/EmployeeDialog.vue"),
      },
      {
        path: "zusatzfunktionen-qualifikationen",
        component: () => import("./employees/edit/forms/ZusatzqualifikationPage.vue"),
      },
    ],
  },
  {
    path: "/mitarbeiter/:id",
    name: "EmployeeEdit",
    component: () => import("./employees/edit/EmployeeView.vue"),
    meta: {
      requiredRoles: <Permission[]>["employee:view"],
    },
    redirect: "allgemein",
    children: [
      {
        path: "allgemein",
        name: "EmployeeFormGeneral",
        component: () => import("./employees/edit/forms/GeneralForm.vue"),
        children: [
          {
            path: "kategorie",
            name: "EmployeeFormEditCategoryDialog",
            props: { listRouteName: "EmployeeFormGeneral" },
            component: () => import("../stammdaten/address/CreateCategoryDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["category:create"],
            },
          },
        ],
      },
      {
        path: "anstellungen",
        name: "EmployeeFormEmployments",
        component: () => import("./employees/edit/EmploymentTableData.vue"),
      },
      {
        path: "anstellungen/erstellen",
        name: "EmployeeFormEmploymentCreate",
        component: () => import("./employees/edit/forms/ManageEmployment.vue"),
      },
      {
        path: "anstellungen/:anstellung",
        name: "EmployeeFormEmployment",
        component: () => import("./employees/edit/forms/ManageEmployment.vue"),
      },
      {
        path: "anstellungsunterbrechung/erstellen",
        name: "EmployeeFormEmploymentInterruptionCreate",
        component: () => import("./employees/edit/forms/ManageEmploymentInterruption.vue"),
      },
      {
        path: "anstellungsunterbrechung/:anstellung",
        name: "EmployeeFormEmploymentInterruption",
        component: () => import("./employees/edit/forms/ManageEmploymentInterruption.vue"),
      },
      {
        path: "zusatzfunktionen-qualifikationen",
        name: "EmployeeFormQualifications",
        component: () => import("./employees/edit/forms/ZusatzqualifikationPage.vue"),
      },
      {
        path: "fehlzeiten",
        name: "EmployeeFormAbsenceDays",
        component: () => import("./employees/edit/AbsenceDaysTable.vue"),
        children: [
          {
            path: "erstellen",
            props: { listRouteName: "EmployeeFormAbsenceDays" },
            name: "AbsenceDaysAppointmentDialogCreate",
            component: () => import("./Appointment/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:create"],
            },
          },
          {
            path: "termine/:appointmentId",
            props: { listRouteName: "EmployeeFormAbsenceDays" },
            name: "AbsenceDaysAppointmentDialogEdit",
            component: () => import("./Appointment/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:view"],
            },
          },
        ],
      },
      {
        path: "fehlzeiten/kalendar",
        name: "AbsenceDaysCalendar",
        component: () => import("./employees/edit/AbsenceDaysCalendar.vue"),
        children: [
          {
            path: "erstellen/:selectedType?",
            props: { listRouteName: "AbsenceDaysCalendar" },
            name: "CalendarAppointmentDialogCreate",
            component: () => import("./Appointment/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:create"],
            },
          },
          {
            path: "termine/:appointmentId",
            props: { listRouteName: "AbsenceDaysCalendar" },
            name: "CalendarAppointmentDialogEdit",
            component: () => import("./Appointment/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:view"],
            },
          },
        ],
      },
      {
        path: "gruppen",
        component: () => import("./employees/edit/forms/GroupsPage.vue"),
      },
      {
        path: "impfstatus",
        component: () => import("./employees/edit/forms/VaccinationPage.vue"),
      },
    ],
  },
  {
    path: "/termine",
    name: "AppointmentPage",
    component: () => import("./Appointment/AppointmentsPage.vue"),
    children: [
      {
        path: ":appointmentId",
        name: "AppointmentDialogEdit",
        component: () => import("./Appointment/AppointmentDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["appointment:view"],
        },
      },
      {
        path: "erstellen",
        name: "AppointmentDialog",
        component: () => import("./Appointment/AppointmentDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["appointment:create"],
        },
      },
    ],
  },
]

export default routes
