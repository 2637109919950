// # # # # # # # # # # # # # # # # # #
// # Automatically generated file!   #
// # Update via: yarn apollo:update  #
// # # # # # # # # # # # # # # # # # #

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Bic: { input: any; output: any; }
  Cid: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Iban: { input: any; output: any; }
  Iid: { input: any; output: any; }
  LongEntityId: { input: any; output: any; }
  NaiveDate: { input: any; output: any; }
  NaiveTime: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
  Oid: { input: any; output: any; }
  Permission: { input: any; output: any; }
  RoleId: { input: any; output: any; }
  StrictEntityId: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Uid: { input: any; output: any; }
};

export type AccessInput = {
  customer?: InputMaybe<CustomerLevel>;
  customerUnit?: InputMaybe<CustomerUnitLevel>;
  institution?: InputMaybe<InstitutionLevel>;
  organization?: InputMaybe<OrganizationLevel>;
  organizationUnit?: InputMaybe<OrganizationUnitLevel>;
};

export enum AccessLevel {
  Customer = 'CUSTOMER',
  Institution = 'INSTITUTION',
  Organization = 'ORGANIZATION',
  OrganizationUnit = 'ORGANIZATION_UNIT'
}

export type Address = {
  __typename?: 'Address';
  active?: Maybe<Scalars['Boolean']['output']>;
  bankAccountHolder?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['NaiveDate']['output']>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  doctor?: Maybe<Scalars['Boolean']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['Iban']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  lastname?: Maybe<Scalars['String']['output']>;
  legalGuardian?: Maybe<Scalars['Boolean']['output']>;
  linkedTo: Array<RestrictedInstitution>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  salutation?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  volunteer?: Maybe<Scalars['Boolean']['output']>;
  volunteerDescription?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressList = {
  __typename?: 'AddressList';
  items: Array<Address>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Analysis = {
  __typename?: 'Analysis';
  date: Scalars['NaiveDate']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  mode: Scalars['String']['output'];
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  type: Scalars['String']['output'];
};

export type AnalysisList = {
  __typename?: 'AnalysisList';
  items: Array<Analysis>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  absence: Scalars['Boolean']['output'];
  children?: Maybe<Array<Child>>;
  employees?: Maybe<Array<Employee>>;
  from: Scalars['DateTime']['output'];
  fullDay: Scalars['Boolean']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  subject: Scalars['String']['output'];
  subtype?: Maybe<AppointmentType>;
  text?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
  type: AppointmentType;
};

export type AppointmentChild = {
  __typename?: 'AppointmentChild';
  appointmentId: Scalars['ObjectId']['output'];
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type AppointmentChildAssignment = {
  appointmentChildId?: InputMaybe<AppointmentChildId>;
  childAppointmentId?: InputMaybe<ChildAppointmentId>;
};

export type AppointmentChildId = {
  appointmentId: Scalars['StrictEntityId']['input'];
  childId: Scalars['ObjectId']['input'];
};

export type AppointmentEmployee = {
  __typename?: 'AppointmentEmployee';
  appointmentId: Scalars['ObjectId']['output'];
  created: Modification;
  employeeId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type AppointmentEmployeeAssignment = {
  appointmentEmployeeId?: InputMaybe<AppointmentEmployeeId>;
  employeeAppointmentId?: InputMaybe<EmployeeAppointmentId>;
};

export type AppointmentEmployeeId = {
  appointmentId: Scalars['StrictEntityId']['input'];
  employeeId: Scalars['ObjectId']['input'];
};

export type AppointmentList = {
  __typename?: 'AppointmentList';
  items: Array<Appointment>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentQueryFilter = {
  childId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  employeeId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  subtype?: InputMaybe<Array<AppointmentType>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Array<AppointmentType>>;
};

export enum AppointmentType {
  /** Seminar day (Seminartag) */
  A = 'A',
  /** Employment ban (Beschäftigungsverbot) */
  B = 'B',
  /** Expert converence (Fachtagung) */
  C = 'C',
  /** Child Sick (Erkrankung Kind) */
  E = 'E',
  /** Advanced training (Fortbildung) */
  F = 'F',
  /** Abscence (Freistellung) */
  G = 'G',
  /** Sick (Krank) */
  K = 'K',
  /** Flex day (Gleittag) */
  L = 'L',
  /** Maternity leave (Mutterschutz) */
  M = 'M',
  /** Regeneration day (Regenerationstag) */
  R = 'R',
  /** Misc (Sonstiger Termin) */
  S = 'S',
  /** Leave (Urlaub) */
  U = 'U',
  /** Change day (Umwandlungstag) */
  W = 'W',
  /** Closing time for advanced team training (Schließzeit Teamfortbildung) */
  Y = 'Y',
  /** Closing time (Schließzeit) */
  Z = 'Z'
}

export type Bank = {
  __typename?: 'Bank';
  accountHolder?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  bankInstitution?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['Bic']['output']>;
  iban?: Maybe<Scalars['Iban']['output']>;
};

export type Birth = {
  __typename?: 'Birth';
  date?: Maybe<Scalars['NaiveDate']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
};

export type BirthInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
};

export type Category = {
  __typename?: 'Category';
  abbreviation?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<ProjectOrganization>;
};

export type CategoryList = {
  __typename?: 'CategoryList';
  items: Array<Category>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Child = {
  __typename?: 'Child';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<SimpleAddress>;
  birth: Birth;
  characteristics?: Maybe<Scalars['String']['output']>;
  childId?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confession?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  contractDate?: Maybe<Scalars['NaiveDate']['output']>;
  contractReceived?: Maybe<Scalars['NaiveDate']['output']>;
  created: Modification;
  furtherSiblings?: Maybe<Scalars['String']['output']>;
  groups: Array<Group>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  modified?: Maybe<Modification>;
  movement: Movement;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  person: Person;
  pictureId?: Maybe<Scalars['String']['output']>;
  previousInstitutions?: Maybe<Array<Scalars['String']['output']>>;
  registration: Registration;
  siblings?: Maybe<Array<Sibling>>;
  symbol?: Maybe<Scalars['String']['output']>;
  vaccination?: Maybe<Vaccination>;
};

export type ChildAppointmentId = {
  appointmentId: Scalars['ObjectId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type ChildGroupId = {
  childId: Scalars['StrictEntityId']['input'];
  groupId: Scalars['ObjectId']['input'];
};

export type ChildList = {
  __typename?: 'ChildList';
  items: Array<Child>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Commune = {
  __typename?: 'Commune';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  comments?: Maybe<Scalars['String']['output']>;
  communeKey: Scalars['String']['output'];
  contactPerson?: Maybe<ContactPerson>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CommuneList = {
  __typename?: 'CommuneList';
  items: Array<Commune>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  letterSalutation?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneMobile?: Maybe<Scalars['String']['output']>;
  phoneWork?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  letterSalutation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  phoneWork?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  address?: Maybe<SimpleAddress>;
  contact?: Maybe<Contact>;
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ContactPersonInput = {
  address?: InputMaybe<SimpleAddressInput>;
  contact?: InputMaybe<ContactInput>;
  name: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Filter to restrict a query
 *
 * Usage:
 * - Specify customer, if you want to filter for a customer.
 * - Specify both customer and organization, if you want to filter for an organization.
 * - Specify all of the three fields, if you want to filter for an institution.
 *
 * This API might change soon.
 */
export type ContextFilter = {
  customer?: InputMaybe<Scalars['ObjectId']['input']>;
  institution?: InputMaybe<Scalars['ObjectId']['input']>;
  organization?: InputMaybe<Scalars['ObjectId']['input']>;
};

/**
 * Oneof input object in GraphQL
 * Oneof input objects requires have exactly one field
 */
export type ContextFilterInput = {
  /**
   * Oneof input object in GraphQL
   * Oneof input objects requires have exactly one field
   */
  customerFilter?: InputMaybe<CustomerFilter>;
  /**
   * Oneof input object in GraphQL
   * Oneof input objects requires have exactly one field
   */
  institutionFilter?: InputMaybe<InstitutionFilter>;
  /**
   * Oneof input object in GraphQL
   * Oneof input objects requires have exactly one field
   */
  organizationFilter?: InputMaybe<OrganizationFilter>;
};

export type CreateAddressInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  doctor?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  legalGuardian?: InputMaybe<Scalars['Boolean']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  volunteerDescription?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAnalysisInput = {
  date: Scalars['NaiveDate']['input'];
  mode: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateAppointmentChildInput = {
  appointmentId: Scalars['StrictEntityId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type CreateAppointmentEmployeeInput = {
  appointmentId: Scalars['StrictEntityId']['input'];
  employeeId: Scalars['StrictEntityId']['input'];
};

export type CreateAppointmentInput = {
  absence: Scalars['Boolean']['input'];
  from: Scalars['DateTime']['input'];
  fullDay: Scalars['Boolean']['input'];
  subject: Scalars['String']['input'];
  subtype?: InputMaybe<AppointmentType>;
  text?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type: AppointmentType;
};

export type CreateCategoryInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateChildInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<SimpleAddressInput>;
  birth: BirthInput;
  characteristics?: InputMaybe<Scalars['String']['input']>;
  childId?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  confession?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactInput>;
  contractDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  contractReceived?: InputMaybe<Scalars['NaiveDate']['input']>;
  furtherSiblings?: InputMaybe<Scalars['String']['input']>;
  movement: MovementInput;
  person: PersonInput;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  previousInstitutions?: InputMaybe<Array<Scalars['String']['input']>>;
  registration: RegistrationInput;
  siblings?: InputMaybe<Array<SiblingInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<VaccinationInput>;
};

export type CreateCommuneInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  comments?: InputMaybe<Scalars['String']['input']>;
  communeKey: Scalars['String']['input'];
  contactPerson?: InputMaybe<ContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEmployeeInput = {
  address?: InputMaybe<SimpleAddressInput>;
  birth?: InputMaybe<BirthInput>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  certificateOfGoodConduct?: InputMaybe<Scalars['NaiveDate']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactInput>;
  education?: InputMaybe<Scalars['String']['input']>;
  educationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  kitalinoExport?: InputMaybe<Scalars['Boolean']['input']>;
  movement: MovementInput;
  order?: InputMaybe<Scalars['Int']['input']>;
  person: PersonInput;
  personnelNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  statisticsEducation?: InputMaybe<Scalars['String']['input']>;
  statisticsPositionType?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<VaccinationInput>;
};

export type CreateEmploymentInput = {
  additionalStaff?: InputMaybe<Scalars['Boolean']['input']>;
  beginMode?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  creditableWorkingHours?: InputMaybe<Scalars['Float']['input']>;
  employeeId: Scalars['ObjectId']['input'];
  endMode?: InputMaybe<Scalars['String']['input']>;
  institutionSectionWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  movement: MovementInput;
  notes?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
  statisticsAreaWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  times?: InputMaybe<TimesInput>;
  vacationDays?: InputMaybe<Scalars['Int']['input']>;
  workingHours?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateFeeTypeInput = {
  accounting: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type CreateFoodTypeInput = {
  description: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CreateGroupChildInput = {
  childId: Scalars['StrictEntityId']['input'];
  groupId: Scalars['StrictEntityId']['input'];
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  times: TimesInput;
  type: Scalars['String']['input'];
};

export type CreateQualificationInput = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateReservationInput = {
  buzeitkat: Scalars['String']['input'];
  childId: Scalars['ObjectId']['input'];
  communeId: Scalars['ObjectId']['input'];
  fbezirk: Scalars['Boolean']['input'];
  from: Scalars['NaiveDate']['input'];
  inclusive: Scalars['Boolean']['input'];
  lunch: Scalars['Boolean']['input'];
  timeCategory: Scalars['String']['input'];
  times: TimesInput;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type: Scalars['String']['input'];
};

export type CreateRoleInput = {
  accessLevel: AccessLevel;
  name: Scalars['String']['input'];
  /** Role Id (Keycloak's group id) */
  templateRole?: InputMaybe<Scalars['RoleId']['input']>;
};

export type CreateSchoolInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  contactPerson?: InputMaybe<ContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type CreateServiceTypeInput = {
  bgcolor: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  times: TimeRangeInput;
  type: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  firstname: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  requiredActions?: InputMaybe<Array<RequiredUserAction>>;
  roleId: Scalars['RoleId']['input'];
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateYouthWelfareOfficeInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  contactPerson?: InputMaybe<ContactPersonInput>;
  description: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type Customer = {
  __typename?: 'Customer';
  cid?: Maybe<Scalars['ObjectId']['output']>;
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
};

/**
 * CustomerFilter is used when filtering from the perspective of a customer using no filter or from the perspective of
 * an admin filtering for a specific customer
 */
export type CustomerFilter = {
  customer: Scalars['ObjectId']['input'];
};

export type CustomerLevel = {
  cid: Scalars['Cid']['input'];
};

export type CustomerUnitLevel = {
  cid: Scalars['Cid']['input'];
  uid: Scalars['Uid']['input'];
};

export type Employee = {
  __typename?: 'Employee';
  address?: Maybe<SimpleAddress>;
  appointments?: Maybe<Array<Appointment>>;
  birth?: Maybe<Birth>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  certificateOfGoodConduct?: Maybe<Scalars['NaiveDate']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  education?: Maybe<Scalars['String']['output']>;
  educationDate?: Maybe<Scalars['NaiveDate']['output']>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  employments?: Maybe<Array<Employment>>;
  id: Scalars['LongEntityId']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  institution?: Maybe<ProjectInstitution>;
  kitalinoExport?: Maybe<Scalars['Boolean']['output']>;
  movement: Movement;
  n: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  organization?: Maybe<ProjectOrganization>;
  person: Person;
  personnelNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  statisticsEducation?: Maybe<Scalars['String']['output']>;
  statisticsPositionType?: Maybe<Scalars['String']['output']>;
  vaccination?: Maybe<Vaccination>;
};

export type EmployeeAppointmentId = {
  appointmentId: Scalars['ObjectId']['input'];
  employeeId: Scalars['StrictEntityId']['input'];
};

export type EmployeeList = {
  __typename?: 'EmployeeList';
  items: Array<Employee>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Employment = {
  __typename?: 'Employment';
  additionalStaff?: Maybe<Scalars['Boolean']['output']>;
  beginMode?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  creditableWorkingHours?: Maybe<Scalars['Float']['output']>;
  employeeId: Scalars['ObjectId']['output'];
  endMode?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  institutionSectionWorkingHours?: Maybe<Array<SectionTime>>;
  movement: Movement;
  n: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<ProjectOrganization>;
  position: Scalars['String']['output'];
  statisticsAreaWorkingHours?: Maybe<Array<SectionTime>>;
  times?: Maybe<Times>;
  vacationDays?: Maybe<Scalars['Int']['output']>;
  workingHours?: Maybe<Scalars['Float']['output']>;
};

export type EmploymentList = {
  __typename?: 'EmploymentList';
  items: Array<Employment>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FeeType = {
  __typename?: 'FeeType';
  accounting: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
};

export type FeeTypeList = {
  __typename?: 'FeeTypeList';
  items: Array<FeeType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FoodType = {
  __typename?: 'FoodType';
  description: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  key: Scalars['String']['output'];
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
};

export type FoodTypeList = {
  __typename?: 'FoodTypeList';
  items: Array<FoodType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Group = {
  __typename?: 'Group';
  children: Array<Child>;
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<ProjectOrganization>;
  times: Times;
  type: Scalars['String']['output'];
};

export type GroupChild = {
  __typename?: 'GroupChild';
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  groupId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type GroupChildAssignment = {
  childGroupId?: InputMaybe<ChildGroupId>;
  groupChildId?: InputMaybe<GroupChildId>;
};

export type GroupChildId = {
  childId: Scalars['ObjectId']['input'];
  groupId: Scalars['StrictEntityId']['input'];
};

export type GroupList = {
  __typename?: 'GroupList';
  items: Array<Group>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  date: Scalars['NaiveDate']['output'];
  key: Scalars['String']['output'];
  kind: HolidayKind;
  name: Scalars['String']['output'];
};

export enum HolidayKind {
  Bavaria = 'BAVARIA',
  Local = 'LOCAL',
  National = 'NATIONAL',
  Special = 'SPECIAL'
}

export type Institution = {
  __typename?: 'Institution';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  iid?: Maybe<Scalars['ObjectId']['output']>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
};

/**
 * InstitutionFilter is used when filtering from the perspective of a institution using no filter, from the perspective
 * of an admin, a customer or an organization filtering for a specific institution
 */
export type InstitutionFilter = {
  customer: Scalars['ObjectId']['input'];
  institution: Scalars['ObjectId']['input'];
  organization: Scalars['ObjectId']['input'];
};

export type InstitutionLevel = {
  cid: Scalars['Cid']['input'];
  iid: Scalars['Iid']['input'];
  oid: Scalars['Oid']['input'];
};

export type InstitutionSection = {
  __typename?: 'InstitutionSection';
  contactPerson?: Maybe<ContactPerson>;
  costCenter?: Maybe<Scalars['String']['output']>;
  hexColor?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shortname?: Maybe<Scalars['String']['output']>;
  type: InstitutionType;
};

export enum InstitutionType {
  ErweiterteMiniKita = 'ERWEITERTE_MINI_KITA',
  HausFuerKinder = 'HAUS_FUER_KINDER',
  Hort = 'HORT',
  Kindergarten = 'KINDERGARTEN',
  Kinderkrippe = 'KINDERKRIPPE',
  KooperativeGanztagesbetreuung = 'KOOPERATIVE_GANZTAGESBETREUUNG',
  MiniKita = 'MINI_KITA',
  Mittagsbetreuung = 'MITTAGSBETREUUNG',
  NetzFuerKinder = 'NETZ_FUER_KINDER',
  Vorschulkindergarten = 'VORSCHULKINDERGARTEN'
}

export enum IntegrativePlace {
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO',
  TwoPointThree = 'TWO_POINT_THREE'
}

export type ListFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberId = {
  __typename?: 'MemberId';
  cid: Scalars['ObjectId']['output'];
  iid: Scalars['ObjectId']['output'];
  oid: Scalars['ObjectId']['output'];
};

export type Modification = {
  __typename?: 'Modification';
  at: Scalars['DateTime']['output'];
};

export type Movement = {
  __typename?: 'Movement';
  entry: Scalars['NaiveDate']['output'];
  exit?: Maybe<Scalars['NaiveDate']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type MovementInput = {
  entry: Scalars['NaiveDate']['input'];
  exit?: InputMaybe<Scalars['NaiveDate']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  createAddress: Address;
  createAnalysis: Analysis;
  createAppointment: Appointment;
  /** Create an assignment between an appointment and a child. */
  createAppointmentChildAssignment: AppointmentChild;
  /** Create an assignment between an appointment and an employee. */
  createAppointmentEmployeeAssignment: AppointmentEmployee;
  createCategory: Category;
  createChild: Child;
  createCommune: Commune;
  createCustomer: ProjectCustomer;
  createEmployee: Employee;
  createEmployment: Employment;
  createFeeType: FeeType;
  createFoodType: FoodType;
  createGroup: Group;
  createGroupChild: GroupChild;
  createInstitution: ProjectInstitution;
  createOrganization: ProjectOrganization;
  createOrganizationUnit: ProjectOrganizationUnit;
  createQualification: Qualification;
  createReservation: Reservation;
  createRole: Role;
  createSchool: School;
  createServiceType: ServiceType;
  createTablePreset: TablePreset;
  createUser: User;
  createYouthWelfareOffice: YouthWelfareOffice;
  linkAddresses: Scalars['Int']['output'];
  linkCategories: Scalars['Int']['output'];
  linkCommunes: Scalars['Int']['output'];
  linkFoodTypes: Scalars['Int']['output'];
  linkQualifications: Scalars['Int']['output'];
  linkSchools: Scalars['Int']['output'];
  linkServiceTypes: Scalars['Int']['output'];
  linkYouthWelfareOffices: Scalars['Int']['output'];
  removeAddresses: Scalars['Int']['output'];
  removeAnalyses: Scalars['Int']['output'];
  /** Remove an assignment between an appointment and a child. */
  removeAppointmentChildAssignment: Scalars['Int']['output'];
  /** Remove an assignment between an appointment and an employee. */
  removeAppointmentEmployeeAssignment: Scalars['Int']['output'];
  removeAppointments: Scalars['Int']['output'];
  removeCategories: Scalars['Int']['output'];
  removeChildren: Scalars['Int']['output'];
  removeCommunes: Scalars['Int']['output'];
  removeCustomers: Scalars['Int']['output'];
  removeEmployees: Scalars['Int']['output'];
  removeEmployments: Scalars['Int']['output'];
  removeFeeTypes: Scalars['Int']['output'];
  removeFoodTypes: Scalars['Int']['output'];
  removeGroupChild: Scalars['Int']['output'];
  removeInstitutions: Scalars['Int']['output'];
  removeOrganizationUnits: Scalars['Int']['output'];
  removeOrganizations: Scalars['Int']['output'];
  removeQualifications: Scalars['Int']['output'];
  removeReservations: Scalars['Int']['output'];
  removeRoles: Scalars['Int']['output'];
  removeSchools: Scalars['Int']['output'];
  removeServiceTypes: Scalars['Int']['output'];
  removeTablePresets: Scalars['Int']['output'];
  removeUsers: Scalars['Int']['output'];
  removeYouthWelfareOffices: Scalars['Int']['output'];
  setDefaultPreset: Scalars['Boolean']['output'];
  unsetDefaultPreset: Scalars['Boolean']['output'];
  updateAddress?: Maybe<Address>;
  updateAnalysis: Analysis;
  updateAppointment: Appointment;
  updateCategory?: Maybe<Category>;
  updateChild: Child;
  updateChildrenState: Scalars['Int']['output'];
  updateCommune?: Maybe<Commune>;
  updateCustomer: ProjectCustomer;
  updateEmployee: Employee;
  updateEmployment: Employment;
  updateFeeType: FeeType;
  updateFoodType?: Maybe<FoodType>;
  updateInstitution: ProjectInstitution;
  updateOrganization: ProjectOrganization;
  updateOrganizationUnit: ProjectOrganizationUnit;
  updateQualification?: Maybe<Qualification>;
  updateReservation: Reservation;
  updateRole: Role;
  updateSchool?: Maybe<School>;
  updateServiceType?: Maybe<ServiceType>;
  updateTablePreset: TablePreset;
  updateUser?: Maybe<User>;
  updateYouthWelfareOffice?: Maybe<YouthWelfareOffice>;
};


export type MutationRootCreateAddressArgs = {
  context: InstitutionFilter;
  input: CreateAddressInput;
};


export type MutationRootCreateAnalysisArgs = {
  context: InstitutionFilter;
  input: CreateAnalysisInput;
};


export type MutationRootCreateAppointmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentInput;
};


export type MutationRootCreateAppointmentChildAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentChildInput;
};


export type MutationRootCreateAppointmentEmployeeAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentEmployeeInput;
};


export type MutationRootCreateCategoryArgs = {
  context: InstitutionFilter;
  input: CreateCategoryInput;
};


export type MutationRootCreateChildArgs = {
  context: InstitutionFilter;
  input: CreateChildInput;
};


export type MutationRootCreateCommuneArgs = {
  context: InstitutionFilter;
  input: CreateCommuneInput;
};


export type MutationRootCreateCustomerArgs = {
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateEmployeeArgs = {
  context: InstitutionFilter;
  input: CreateEmployeeInput;
};


export type MutationRootCreateEmploymentArgs = {
  context: InstitutionFilter;
  input: CreateEmploymentInput;
};


export type MutationRootCreateFeeTypeArgs = {
  context: InstitutionFilter;
  input: CreateFeeTypeInput;
};


export type MutationRootCreateFoodTypeArgs = {
  context: InstitutionFilter;
  input: CreateFoodTypeInput;
};


export type MutationRootCreateGroupArgs = {
  context: InstitutionFilter;
  input: CreateGroupInput;
};


export type MutationRootCreateGroupChildArgs = {
  context: InstitutionFilter;
  input: CreateGroupChildInput;
};


export type MutationRootCreateInstitutionArgs = {
  context: OrganizationFilter;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateOrganizationArgs = {
  context: CustomerFilter;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateOrganizationUnitArgs = {
  context: ContextFilterInput;
  members: Array<InstitutionFilter>;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateQualificationArgs = {
  context: InstitutionFilter;
  input: CreateQualificationInput;
};


export type MutationRootCreateReservationArgs = {
  context: InstitutionFilter;
  input: CreateReservationInput;
};


export type MutationRootCreateRoleArgs = {
  input: CreateRoleInput;
  org: CustomerFilter;
};


export type MutationRootCreateSchoolArgs = {
  context: InstitutionFilter;
  input: CreateSchoolInput;
};


export type MutationRootCreateServiceTypeArgs = {
  context: InstitutionFilter;
  input: CreateServiceTypeInput;
};


export type MutationRootCreateTablePresetArgs = {
  columns: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  table: Table;
};


export type MutationRootCreateUserArgs = {
  access: AccessInput;
  user: CreateUserInput;
};


export type MutationRootCreateYouthWelfareOfficeArgs = {
  context: InstitutionFilter;
  input: CreateYouthWelfareOfficeInput;
};


export type MutationRootLinkAddressesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkCategoriesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkCommunesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkFoodTypesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkQualificationsArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkSchoolsArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkServiceTypesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkYouthWelfareOfficesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAddressesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAnalysesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAppointmentChildAssignmentArgs = {
  ids: Array<AppointmentChildAssignment>;
};


export type MutationRootRemoveAppointmentEmployeeAssignmentArgs = {
  ids: Array<AppointmentEmployeeAssignment>;
};


export type MutationRootRemoveAppointmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCategoriesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveChildrenArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCommunesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCustomersArgs = {
  ids: Array<StrictCustomerId>;
};


export type MutationRootRemoveEmployeesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmploymentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveFeeTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveFoodTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveGroupChildArgs = {
  ids: Array<GroupChildAssignment>;
};


export type MutationRootRemoveInstitutionsArgs = {
  ids: Array<StrictInstitutionId>;
};


export type MutationRootRemoveOrganizationUnitsArgs = {
  ids: Array<StrictOrganizationUnitId>;
};


export type MutationRootRemoveOrganizationsArgs = {
  ids: Array<StrictOrganizationId>;
};


export type MutationRootRemoveQualificationsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveReservationsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveRolesArgs = {
  ids: Array<Scalars['RoleId']['input']>;
};


export type MutationRootRemoveSchoolsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveServiceTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveTablePresetsArgs = {
  ids: Array<Scalars['ObjectId']['input']>;
};


export type MutationRootRemoveUsersArgs = {
  ids: Array<Scalars['LongEntityId']['input']>;
};


export type MutationRootRemoveYouthWelfareOfficesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootSetDefaultPresetArgs = {
  id: Scalars['ObjectId']['input'];
  table: Table;
};


export type MutationRootUnsetDefaultPresetArgs = {
  table: Table;
};


export type MutationRootUpdateAddressArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAddressInput;
};


export type MutationRootUpdateAnalysisArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAnalysisInput;
};


export type MutationRootUpdateAppointmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAppointmentInput;
};


export type MutationRootUpdateCategoryArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCategoryInput;
};


export type MutationRootUpdateChildArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateChildInput;
};


export type MutationRootUpdateChildrenStateArgs = {
  inputs: Array<UpdateChildStateInput>;
};


export type MutationRootUpdateCommuneArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCommuneInput;
};


export type MutationRootUpdateCustomerArgs = {
  context: CustomerFilter;
  input: UpdateProjectCustomerInput;
};


export type MutationRootUpdateEmployeeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmployeeInput;
};


export type MutationRootUpdateEmploymentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmploymentInput;
};


export type MutationRootUpdateFeeTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateFeeTypeInput;
};


export type MutationRootUpdateFoodTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateFoodTypeInput;
};


export type MutationRootUpdateInstitutionArgs = {
  context: InstitutionFilter;
  input: UpdateProjectInstitutionInput;
};


export type MutationRootUpdateOrganizationArgs = {
  context: OrganizationFilter;
  input: UpdateProjectOrganizationInput;
};


export type MutationRootUpdateOrganizationUnitArgs = {
  context: StrictOrganizationUnitId;
  input: UpdateProjectOrganizationUnitInput;
};


export type MutationRootUpdateQualificationArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateQualificationInput;
};


export type MutationRootUpdateReservationArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateReservationInput;
};


export type MutationRootUpdateRoleArgs = {
  id: Scalars['RoleId']['input'];
  input: UpdateRoleInput;
};


export type MutationRootUpdateSchoolArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateSchoolInput;
};


export type MutationRootUpdateServiceTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateServiceTypeInput;
};


export type MutationRootUpdateTablePresetArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRootUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['UUID']['input'];
};


export type MutationRootUpdateYouthWelfareOfficeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateYouthWelfareOfficeInput;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  integrativePlace?: Maybe<IntegrativePlace>;
  placesNecessity?: Maybe<PlaceNecessity>;
  times?: Maybe<Times>;
};

export type Organization = {
  __typename?: 'Organization';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
};

/**
 * OrganizationFilter is used when filtering from the perspective of a organization using no filter, from the
 * perspective of an admin or a customer filtering for a specific organization
 */
export type OrganizationFilter = {
  customer: Scalars['ObjectId']['input'];
  organization: Scalars['ObjectId']['input'];
};

export type OrganizationLevel = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
};

export type OrganizationUnit = {
  __typename?: 'OrganizationUnit';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  members: Array<MemberId>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
  uid?: Maybe<Scalars['ObjectId']['output']>;
};

export type OrganizationUnitLevel = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
  uid: Scalars['Uid']['input'];
};

export type Person = {
  __typename?: 'Person';
  citizenships?: Maybe<Array<Scalars['String']['output']>>;
  confession?: Maybe<Scalars['String']['output']>;
  denomination?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  isGermanFirstLanguage?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastname: Scalars['String']['output'];
  middlename?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PersonInput = {
  citizenships?: InputMaybe<Array<Scalars['String']['input']>>;
  confession?: InputMaybe<Scalars['String']['input']>;
  denomination?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  isGermanFirstLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastname: Scalars['String']['input'];
  middlename?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Place = {
  __typename?: 'Place';
  adolescent?: Maybe<Scalars['Int']['output']>;
  approved?: Maybe<Scalars['Int']['output']>;
  general?: Maybe<Scalars['Int']['output']>;
  integrative?: Maybe<Scalars['Int']['output']>;
  meal?: Maybe<Scalars['Int']['output']>;
  schoolChildren?: Maybe<Scalars['Int']['output']>;
  toddler?: Maybe<Scalars['Int']['output']>;
};

export enum PlaceNecessity {
  Three = 'THREE',
  TwoPointFive = 'TWO_POINT_FIVE'
}

export type ProjectCustomer = {
  __typename?: 'ProjectCustomer';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  cid?: Maybe<Scalars['ObjectId']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customerNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ProjectCustomerList = {
  __typename?: 'ProjectCustomerList';
  items: Array<ProjectCustomer>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectInstitution = {
  __typename?: 'ProjectInstitution';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  banks?: Maybe<Array<Bank>>;
  commune?: Maybe<Commune>;
  communeId?: Maybe<Scalars['ObjectId']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customer?: Maybe<Customer>;
  hasAssumptionDay: Scalars['Boolean']['output'];
  hasAugsburgerPeaceFestival: Scalars['Boolean']['output'];
  id: Scalars['LongEntityId']['output'];
  iid?: Maybe<Scalars['ObjectId']['output']>;
  institutionNumber?: Maybe<Scalars['String']['output']>;
  internalInstitutionNumber?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  openingHours?: Maybe<OpeningHours>;
  operatingPermitYear?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  place?: Maybe<Place>;
  sections?: Maybe<Array<InstitutionSection>>;
  sepaConfiguration?: Maybe<SepaConfiguration>;
  statistics?: Maybe<Statistics>;
  type: InstitutionType;
  yearOfConstruction?: Maybe<Scalars['String']['output']>;
};

export type ProjectInstitutionList = {
  __typename?: 'ProjectInstitutionList';
  items: Array<ProjectInstitution>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectOrganization = {
  __typename?: 'ProjectOrganization';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfMembers?: Maybe<Scalars['Int']['output']>;
  oid?: Maybe<Scalars['ObjectId']['output']>;
};

export type ProjectOrganizationList = {
  __typename?: 'ProjectOrganizationList';
  items: Array<ProjectOrganization>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectOrganizationUnit = {
  __typename?: 'ProjectOrganizationUnit';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  members: Array<MemberId>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
  uid?: Maybe<Scalars['ObjectId']['output']>;
};

export type ProjectOrganizationUnitList = {
  __typename?: 'ProjectOrganizationUnitList';
  items: Array<ProjectOrganizationUnit>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Qualification = {
  __typename?: 'Qualification';
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<ProjectOrganization>;
  type: Scalars['String']['output'];
};

export type QualificationList = {
  __typename?: 'QualificationList';
  items: Array<Qualification>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  addressById?: Maybe<Address>;
  addresses: AddressList;
  analyses: AnalysisList;
  analysisById?: Maybe<Analysis>;
  appointmentById?: Maybe<Appointment>;
  appointments: AppointmentList;
  /**
   * Get appointments filtered.
   *
   * Filter `from` and `to` are **incusive**.
   */
  appointmentsFiltered: Array<Appointment>;
  /**
   * Get all children with birthday in the next days.
   *
   * If no `day_offset` is provided, `30` days are assumed.
   */
  birthdays: ChildList;
  categories: CategoryList;
  categoryById?: Maybe<Category>;
  childById?: Maybe<Child>;
  children: ChildList;
  communeById?: Maybe<Commune>;
  communes: CommuneList;
  customerById?: Maybe<ProjectCustomer>;
  customerByName?: Maybe<ProjectCustomer>;
  customers: ProjectCustomerList;
  employeeById?: Maybe<Employee>;
  employees: EmployeeList;
  employmentById?: Maybe<Employment>;
  employments: EmploymentList;
  feeTypeById?: Maybe<FeeType>;
  feeTypes: FeeTypeList;
  foodTypeById?: Maybe<FoodType>;
  foodTypes: FoodTypeList;
  groupById?: Maybe<Group>;
  groups: GroupList;
  /**
   * Get holidays in range.
   *
   * Arguments `from` and `to` are **incusive**.
   */
  holidaysInRange: Array<Holiday>;
  institutionById?: Maybe<ProjectInstitution>;
  institutionByName?: Maybe<ProjectInstitution>;
  institutions: ProjectInstitutionList;
  organizationById?: Maybe<ProjectOrganization>;
  organizationByName?: Maybe<ProjectOrganization>;
  organizationUnitById?: Maybe<ProjectOrganizationUnit>;
  organizationUnits: ProjectOrganizationUnitList;
  organizations: ProjectOrganizationList;
  qualificationById?: Maybe<Qualification>;
  qualifications: QualificationList;
  reservationById?: Maybe<Reservation>;
  reservations: ReservationList;
  roleById: Role;
  roles: RoleList;
  schoolById?: Maybe<School>;
  schools: SchoolList;
  serviceTypeById?: Maybe<ServiceType>;
  serviceTypes: ServiceTypeList;
  tablePresets: TablePresetList;
  userById?: Maybe<User>;
  users: UserList;
  youthWelfareOfficeById?: Maybe<YouthWelfareOffice>;
  youthWelfareOffices: YouthWelfareOfficeList;
};


export type QueryRootAddressByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAddressesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAnalysesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAnalysisByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAppointmentByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAppointmentsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAppointmentsFilteredArgs = {
  filter: AppointmentQueryFilter;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootBirthdaysArgs = {
  dayOffset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCategoriesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCategoryByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootChildByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootChildrenArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCommuneByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootCommunesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCustomerByIdArgs = {
  customerId: StrictCustomerId;
};


export type QueryRootCustomerByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryRootCustomersArgs = {
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootEmployeeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootEmployeesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootEmploymentByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootEmploymentsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootFeeTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootFeeTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootFoodTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootFoodTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootGroupByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootGroupsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootHolidaysInRangeArgs = {
  context: InstitutionFilter;
  from: Scalars['NaiveDate']['input'];
  to: Scalars['NaiveDate']['input'];
};


export type QueryRootInstitutionByIdArgs = {
  institutionId: StrictInstitutionId;
};


export type QueryRootInstitutionByNameArgs = {
  context: ContextFilter;
  name: Scalars['String']['input'];
};


export type QueryRootInstitutionsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootOrganizationByIdArgs = {
  organizationId: StrictOrganizationId;
};


export type QueryRootOrganizationByNameArgs = {
  context: ContextFilter;
  name: Scalars['String']['input'];
};


export type QueryRootOrganizationUnitByIdArgs = {
  context?: InputMaybe<ContextFilter>;
  organizationUnitId: StrictOrganizationUnitId;
};


export type QueryRootOrganizationUnitsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootOrganizationsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootQualificationByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootQualificationsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootReservationByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootReservationsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootRoleByIdArgs = {
  id: Scalars['RoleId']['input'];
};


export type QueryRootRolesArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootSchoolByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootSchoolsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootServiceTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootServiceTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootTablePresetsArgs = {
  filter?: InputMaybe<ListFilter>;
  table?: InputMaybe<Table>;
};


export type QueryRootUserByIdArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryRootUsersArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootYouthWelfareOfficeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootYouthWelfareOfficesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};

export type Registration = {
  __typename?: 'Registration';
  date: Scalars['NaiveDate']['output'];
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RegistrationInput = {
  date: Scalars['NaiveDate']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum RequiredUserAction {
  UpdatePassword = 'UPDATE_PASSWORD'
}

export type Reservation = {
  __typename?: 'Reservation';
  buzeitkat: Scalars['String']['output'];
  childId: Scalars['ObjectId']['output'];
  communeId: Scalars['ObjectId']['output'];
  fbezirk: Scalars['Boolean']['output'];
  from: Scalars['NaiveDate']['output'];
  id: Scalars['LongEntityId']['output'];
  inclusive: Scalars['Boolean']['output'];
  institution?: Maybe<ProjectInstitution>;
  lunch: Scalars['Boolean']['output'];
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  timeCategory: Scalars['String']['output'];
  times: Times;
  to?: Maybe<Scalars['NaiveDate']['output']>;
  type: Scalars['String']['output'];
};

export type ReservationList = {
  __typename?: 'ReservationList';
  items: Array<Reservation>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RestrictedInstitution = {
  __typename?: 'RestrictedInstitution';
  id: Scalars['LongEntityId']['output'];
  name: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  accessLevel: AccessLevel;
  cid?: Maybe<Scalars['Cid']['output']>;
  id: Scalars['RoleId']['output'];
  n?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  permissions: Array<Scalars['Permission']['output']>;
};

export type RoleList = {
  __typename?: 'RoleList';
  items: Array<Role>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type School = {
  __typename?: 'School';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPerson?: Maybe<ContactPerson>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type SchoolList = {
  __typename?: 'SchoolList';
  items: Array<School>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SectionTime = {
  __typename?: 'SectionTime';
  name: Scalars['String']['output'];
  time: Scalars['Float']['output'];
};

export type SectionTimeInput = {
  name: Scalars['String']['input'];
  time: Scalars['Float']['input'];
};

export type SepaConfiguration = {
  __typename?: 'SepaConfiguration';
  paymentInstitution?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sepaCreditorIdentificationNumber?: Maybe<Scalars['String']['output']>;
};

export type ServiceType = {
  __typename?: 'ServiceType';
  bgcolor: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  identifier: Scalars['String']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  times: TimeRange;
  type: Scalars['String']['output'];
};

export type ServiceTypeList = {
  __typename?: 'ServiceTypeList';
  items: Array<ServiceType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Sibling = {
  __typename?: 'Sibling';
  birthdate: Scalars['NaiveDate']['output'];
  firstname: Scalars['String']['output'];
};

export type SiblingInput = {
  birthdate: Scalars['NaiveDate']['input'];
  firstname: Scalars['String']['input'];
};

export type SimpleAddress = {
  __typename?: 'SimpleAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SimpleAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Statistics = {
  __typename?: 'Statistics';
  close?: Maybe<Scalars['NaiveTime']['output']>;
  closeMidday?: Maybe<Scalars['Boolean']['output']>;
  companyEmployees?: Maybe<Scalars['Boolean']['output']>;
  legalForm?: Maybe<Scalars['String']['output']>;
  notFixedStructure?: Maybe<Scalars['Boolean']['output']>;
  open?: Maybe<Scalars['NaiveTime']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  parentInitiatives?: Maybe<Scalars['Boolean']['output']>;
  regionalNumber?: Maybe<Scalars['Int']['output']>;
  statisticalNumber?: Maybe<Scalars['Int']['output']>;
};

export type StrictCustomerId = {
  cid: Scalars['Cid']['input'];
};

export type StrictInstitutionId = {
  cid: Scalars['Cid']['input'];
  iid: Scalars['Iid']['input'];
  oid: Scalars['Oid']['input'];
};

export type StrictOrganizationId = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
};

export type StrictOrganizationUnitId = {
  cid: Scalars['Cid']['input'];
  oid?: InputMaybe<Scalars['Oid']['input']>;
  uid: Scalars['Uid']['input'];
};

/** Every possible table in the application, that might need a preset */
export enum Table {
  Addresses = 'ADDRESSES',
  Appointments = 'APPOINTMENTS',
  Banks = 'BANKS',
  Categories = 'CATEGORIES',
  Children = 'CHILDREN',
  Communes = 'COMMUNES',
  Customers = 'CUSTOMERS',
  Employees = 'EMPLOYEES',
  FoodTypes = 'FOOD_TYPES',
  Groups = 'GROUPS',
  Institutions = 'INSTITUTIONS',
  Organizations = 'ORGANIZATIONS',
  OrganizationUnits = 'ORGANIZATION_UNITS',
  Qualifications = 'QUALIFICATIONS',
  Roles = 'ROLES',
  Schools = 'SCHOOLS',
  ServiceTypes = 'SERVICE_TYPES',
  Users = 'USERS',
  YouthWelfareOffices = 'YOUTH_WELFARE_OFFICES'
}

export type TablePreset = {
  __typename?: 'TablePreset';
  columns: Array<Scalars['String']['output']>;
  id: Scalars['ObjectId']['output'];
  isGlobal: Scalars['Boolean']['output'];
  isGlobalDefault: Scalars['Boolean']['output'];
  isUserDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  table: Table;
};

export type TablePresetList = {
  __typename?: 'TablePresetList';
  items: Array<TablePreset>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type TimeRangeInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type Times = {
  __typename?: 'Times';
  fr?: Maybe<Array<TimeRange>>;
  mo?: Maybe<Array<TimeRange>>;
  sa?: Maybe<Array<TimeRange>>;
  su?: Maybe<Array<TimeRange>>;
  th?: Maybe<Array<TimeRange>>;
  tu?: Maybe<Array<TimeRange>>;
  we?: Maybe<Array<TimeRange>>;
};

export type TimesInput = {
  fr?: InputMaybe<Array<TimeRangeInput>>;
  mo?: InputMaybe<Array<TimeRangeInput>>;
  sa?: InputMaybe<Array<TimeRangeInput>>;
  su?: InputMaybe<Array<TimeRangeInput>>;
  th?: InputMaybe<Array<TimeRangeInput>>;
  tu?: InputMaybe<Array<TimeRangeInput>>;
  we?: InputMaybe<Array<TimeRangeInput>>;
};

export type UpdateAddressInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  doctor?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  legalGuardian?: InputMaybe<Scalars['Boolean']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  volunteerDescription?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnalysisInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAppointmentInput = {
  absence?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  fullDay?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subtype?: InputMaybe<AppointmentType>;
  text?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<AppointmentType>;
};

export type UpdateBankInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankInstitution?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['Bic']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
};

export type UpdateBirthInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChildInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  birth?: InputMaybe<UpdateBirthInput>;
  characteristics?: InputMaybe<Scalars['String']['input']>;
  childId?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  confession?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  contractDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  contractReceived?: InputMaybe<Scalars['NaiveDate']['input']>;
  furtherSiblings?: InputMaybe<Scalars['String']['input']>;
  movement?: InputMaybe<UpdateMovementInput>;
  person?: InputMaybe<UpdatePersonInput>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  previousInstitutions?: InputMaybe<Array<Scalars['String']['input']>>;
  registration?: InputMaybe<UpdateRegistrationInput>;
  siblings?: InputMaybe<Array<UpdateSiblingInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<UpdateVaccinationInput>;
};

export type UpdateChildStateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['StrictEntityId']['input'];
};

export type UpdateCommuneInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  comments?: InputMaybe<Scalars['String']['input']>;
  communeKey?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  letterSalutation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  phoneWork?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactPersonInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contact?: InputMaybe<UpdateContactInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployeeInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  birth?: InputMaybe<UpdateBirthInput>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  certificateOfGoodConduct?: InputMaybe<Scalars['NaiveDate']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  education?: InputMaybe<Scalars['String']['input']>;
  educationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  kitalinoExport?: InputMaybe<Scalars['Boolean']['input']>;
  movement?: InputMaybe<UpdateMovementInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  person?: InputMaybe<UpdatePersonInput>;
  personnelNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  statisticsEducation?: InputMaybe<Scalars['String']['input']>;
  statisticsPositionType?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<UpdateVaccinationInput>;
};

export type UpdateEmploymentInput = {
  additionalStaff?: InputMaybe<Scalars['Boolean']['input']>;
  beginMode?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  creditableWorkingHours?: InputMaybe<Scalars['Float']['input']>;
  employeeId?: InputMaybe<Scalars['ObjectId']['input']>;
  endMode?: InputMaybe<Scalars['String']['input']>;
  institutionSectionWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  movement?: InputMaybe<UpdateMovementInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  statisticsAreaWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  times?: InputMaybe<UpdateTimesInput>;
  vacationDays?: InputMaybe<Scalars['Int']['input']>;
  workingHours?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFeeTypeInput = {
  accounting?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFoodTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInstitutionSectionInput = {
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  hexColor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortname?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InstitutionType>;
};

export type UpdateMovementInput = {
  entry?: InputMaybe<Scalars['NaiveDate']['input']>;
  exit?: InputMaybe<Scalars['NaiveDate']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOpeningHoursInput = {
  integrativePlace?: InputMaybe<IntegrativePlace>;
  placesNecessity?: InputMaybe<PlaceNecessity>;
  times?: InputMaybe<TimesInput>;
};

export type UpdatePersonInput = {
  citizenships?: InputMaybe<Array<Scalars['String']['input']>>;
  confession?: InputMaybe<Scalars['String']['input']>;
  denomination?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isGermanFirstLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  middlename?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlaceInput = {
  adolescent?: InputMaybe<Scalars['Int']['input']>;
  approved?: InputMaybe<Scalars['Int']['input']>;
  general?: InputMaybe<Scalars['Int']['input']>;
  integrative?: InputMaybe<Scalars['Int']['input']>;
  meal?: InputMaybe<Scalars['Int']['input']>;
  schoolChildren?: InputMaybe<Scalars['Int']['input']>;
  toddler?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectCustomerInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInstitutionInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  banks?: InputMaybe<Array<UpdateBankInput>>;
  communeId?: InputMaybe<Scalars['ObjectId']['input']>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  institutionNumber?: InputMaybe<Scalars['String']['input']>;
  internalInstitutionNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<UpdateOpeningHoursInput>;
  operatingPermitYear?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<UpdatePlaceInput>;
  sections?: InputMaybe<Array<UpdateInstitutionSectionInput>>;
  sepaConfiguration?: InputMaybe<UpdateSepaConfigurationInput>;
  statistics?: InputMaybe<UpdateStatisticsInput>;
  type?: InputMaybe<InstitutionType>;
  yearOfConstruction?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectOrganizationInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  isUnit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfMembers?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectOrganizationUnitInput = {
  members?: InputMaybe<Array<InstitutionFilter>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQualificationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRegistrationInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReservationInput = {
  buzeitkat?: InputMaybe<Scalars['String']['input']>;
  childId?: InputMaybe<Scalars['ObjectId']['input']>;
  communeId?: InputMaybe<Scalars['ObjectId']['input']>;
  fbezirk?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  inclusive?: InputMaybe<Scalars['Boolean']['input']>;
  lunch?: InputMaybe<Scalars['Boolean']['input']>;
  timeCategory?: InputMaybe<Scalars['String']['input']>;
  times?: InputMaybe<UpdateTimesInput>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  accessLevel?: InputMaybe<AccessLevel>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['Permission']['input']>>;
};

export type UpdateSchoolInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSepaConfigurationInput = {
  paymentInstitution?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateServiceTypeInput = {
  bgcolor?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  times?: InputMaybe<UpdateTimeRangeInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiblingInput = {
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSimpleAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatisticsInput = {
  close?: InputMaybe<Scalars['NaiveTime']['input']>;
  closeMidday?: InputMaybe<Scalars['Boolean']['input']>;
  companyEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  legalForm?: InputMaybe<Scalars['String']['input']>;
  notFixedStructure?: InputMaybe<Scalars['Boolean']['input']>;
  open?: InputMaybe<Scalars['NaiveTime']['input']>;
  organizationType?: InputMaybe<Scalars['String']['input']>;
  parentInitiatives?: InputMaybe<Scalars['Boolean']['input']>;
  regionalNumber?: InputMaybe<Scalars['Int']['input']>;
  statisticalNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTimeRangeInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTimesInput = {
  fr?: InputMaybe<Array<TimeRangeInput>>;
  mo?: InputMaybe<Array<TimeRangeInput>>;
  sa?: InputMaybe<Array<TimeRangeInput>>;
  su?: InputMaybe<Array<TimeRangeInput>>;
  th?: InputMaybe<Array<TimeRangeInput>>;
  tu?: InputMaybe<Array<TimeRangeInput>>;
  we?: InputMaybe<Array<TimeRangeInput>>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['RoleId']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVaccinationInput = {
  contraindicationEndDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  firstConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  proofDoubts?: InputMaybe<Scalars['String']['input']>;
  reportDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  secondConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  status?: InputMaybe<VaccinationStatus>;
};

export type UpdateYouthWelfareOfficeInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  customer?: Maybe<Customer>;
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<Institution>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  n: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  organizationUnit?: Maybe<OrganizationUnit>;
  phone?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['RoleId']['output'];
  salutation?: Maybe<Scalars['String']['output']>;
  userId: Scalars['UUID']['output'];
  username: Scalars['String']['output'];
};

export type UserList = {
  __typename?: 'UserList';
  items: Array<User>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  /**
   * This is date since contraindication and was called `ismmoeglab` in the
   * legacy application.
   */
  contraindicationEndDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the date of the first vaccination and was called `ism1dat` in the
   * legacy application.
   */
  firstConfirmationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the date of the last change of the vaccination status and was
   * called `ismstatdat` in the legacy application.
   */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * This is the description of complications and was called `ismzweifel` in
   * the legacy application.
   */
  proofDoubts?: Maybe<Scalars['String']['output']>;
  /**
   * This is the date at which the vaccination was reported to the health
   * department and was called `ismmelddat` in the legacy application.
   */
  reportDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the date of the first vaccination and was called `ism2dat` in the
   * legacy application.
   */
  secondConfirmationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the vaccination status and was called `ismstatus` in the legacy
   * application.
   */
  status?: Maybe<VaccinationStatus>;
};

export type VaccinationInput = {
  /**
   * This is date since contraindication and was called `ismmoeglab` in the
   * legacy application.
   */
  contraindicationEndDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the date of the first vaccination and was called `ism1dat` in the
   * legacy application.
   */
  firstConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the date of the last change of the vaccination status and was
   * called `ismstatdat` in the legacy application.
   */
  lastStatusUpdate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * This is the description of complications and was called `ismzweifel` in
   * the legacy application.
   */
  proofDoubts?: InputMaybe<Scalars['String']['input']>;
  /**
   * This is the date at which the vaccination was reported to the health
   * department and was called `ismmelddat` in the legacy application.
   */
  reportDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the date of the first vaccination and was called `ism2dat` in the
   * legacy application.
   */
  secondConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the vaccination status and was called `ismstatus` in the legacy
   * application.
   */
  status?: InputMaybe<VaccinationStatus>;
};

export enum VaccinationStatus {
  /** Vaccination fulfilled, person is below 12 months old */
  J0 = 'J0',
  /** Vaccination fulfilled, person below 12 months had first shot */
  J0_1 = 'J0_1',
  /** Vaccination fulfilled, person below 12 months had second shot */
  J0_2 = 'J0_2',
  /** Vaccination fulfilled, person below 12 has all required shots */
  J0_12 = 'J0_12',
  /** Vaccination fulfilled, person is below 24 months old */
  J1 = 'J1',
  /** Vaccination fulfilled, person below 24 months had second shot */
  J1_2 = 'J1_2',
  /** Vaccination fulfilled, person is has all required shots */
  J2 = 'J2',
  /** Vaccination fulfilled, person has attested immunity */
  J3 = 'J3',
  /** Vaccination fulfilled, person has attested contraindication */
  J4 = 'J4',
  /** Vaccination fulfilled, person with contraintication has all required shots */
  J4_1 = 'J4_1',
  /** Vaccination fulfilled, person has external proof */
  J5 = 'J5',
  /**
   * Vaccination incomplete, since no proof was provided until 2022-07-31;
   * Person is in the institution since before 2020-03-01
   */
  N1 = 'N1',
  /** Vaccination incomplete, because of doubts about the provided proof */
  N2 = 'N2',
  /** Vaccination incomplete */
  N3 = 'N3',
  /** Vaccination incomplete, since no proof was provided at follow-up inspection */
  N4 = 'N4',
  N5 = 'N5',
  /** Vaccination incomplete */
  N6 = 'N6',
  /**
   * Vaccination incomplete, since no proof was provided;
   * Person was accepted anyway
   */
  N7 = 'N7',
  /**
   * Vaccination incomplete, since no proof was provided;
   * Person was accepted due to authority exception (e.g. missing vaccine)
   */
  N8 = 'N8'
}

export type YouthWelfareOffice = {
  __typename?: 'YouthWelfareOffice';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPerson?: Maybe<ContactPerson>;
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type YouthWelfareOfficeList = {
  __typename?: 'YouthWelfareOfficeList';
  items: Array<YouthWelfareOffice>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};
