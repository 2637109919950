import { createI18n } from "vue-i18n"

import de from "./locales/de.json"

export const DEFAULT_LANGUAGE = "de"
export const language = localStorage.getItem("language") || DEFAULT_LANGUAGE

export const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: language,
  messages: { de },
  globalInjection: true,
  warnHtmlMessage: false,
})
