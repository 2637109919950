import { type Ref, reactive, ref, watch } from "vue"

export type DataTableCache<T> = ReturnType<typeof useDataTableCache<T>>

export function useDataTableCache<T>(sourceItems: Ref<T[]>) {
  const items = ref<T[]>([]) as Ref<T[]>
  watch(sourceItems, (list) => list && (items.value = list), { deep: true })

  function set(newItems: T[]) {
    items.value = newItems
  }

  return reactive({
    items,
    set,
  })
}
