import { i18n } from "@/i18n"
import { type TableConfig } from "@/types"

const config: TableConfig = {
  columnDefs: [
    { field: "n", header: i18n.global.t("labels.id"), size: "id" },
    {
      field: "name",
      header: i18n.global.t("labels.name"),
      size: "medium",
    },
    {
      field: "institutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsnummer.label"),
      size: "medium",
      active: false,
    },
    {
      field: "internalInstitutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.interne_einrichtungsnummer.label"),
      size: "medium",
      active: false,
    },
    {
      field: "type",
      mappedField: "institutionTypeName",
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsart.label"),
      size: "medium",
      active: false,
    },
    {
      field: "communeId",
      mappedField: "communeName",
      header: i18n.global.t("labels.sitzgemeinde"),
      size: "medium",
      active: false,
    },
    {
      field: "yearOfConstruction",
      header: i18n.global.t("admin.einrichtungen.felder.baujahr.label"),
      size: "small",
      active: false,
    },
    {
      field: "operatingPermitYear",
      header: i18n.global.t("admin.einrichtungen.felder.betriebserlaubnis.label"),
      size: "medium",
      active: false,
    },
    {
      field: "address.street",
      header: i18n.global.t("labels.address.street"),
      size: "big",
      active: false,
    },
    {
      field: "address.zipCode",
      header: i18n.global.t("labels.address.zip_code"),
      size: "small",
      active: false,
    },
    {
      field: "address.city",
      header: i18n.global.t("labels.address.ort"),
      size: "medium",
      active: false,
    },
    {
      field: "address.district",
      mappedField: "address.districtName",
      header: i18n.global.t("labels.address.district"),
      size: "medium",
      active: false,
    },
    {
      field: "address.country",
      mappedField: "address.countryName",
      header: i18n.global.t("labels.address.country"),
      size: "medium",
      active: false,
    },
  ],
}

export default config
