import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const routes: RouteRecordRaw[] = [
  {
    path: "/kinder",
    name: "ChildrenPage",
    component: () => import("./children/ChildrenPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:list"],
    },
  },
  {
    name: "ChildCreate",
    path: "/kinder/erstellen",
    redirect: { name: "ChildCreateFormGeneral" },
    component: () => import("./children/views/ChildCreate.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:create"],
    },
    children: [
      {
        path: "allgemein",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "general" },
        children: [
          {
            name: "ChildCreateFormGeneral",
            path: "",
            component: () => import("./children/forms/FormGeneral.vue"),
          },
        ],
      },
      {
        path: "verschiedenes",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "miscellaneous" },
        children: [
          {
            name: "ChildCreateFormMiscellaneous",
            path: "",
            component: () => import("./children/forms/FormMiscellaneous.vue"),
          },
        ],
      },
      {
        path: "impfstatus",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "vaccination" },
        children: [
          {
            name: "ChildCreateFormVaccination",
            path: "",
            component: () => import("./children/forms/FormVaccination.vue"),
          },
        ],
      },
      {
        path: "bezugsperson-1",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "firstGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardianFirst",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 0 },
          },
        ],
      },
      {
        path: "bezugsperson-2",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "secondGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardianSecond",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 1 },
          },
        ],
      },
      {
        path: "bezugsperson-3",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "additionalGuardian" },
        children: [
          {
            name: "ChildCreateFormLocalGuardian",
            path: "",
            component: () => import("./children/forms/FormLocalGuardian.vue"),
            props: { index: 2 },
          },
        ],
      },
      {
        path: "gruppen",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "groups" },
        children: [
          {
            name: "ChildCreateFormGroups",
            path: "",
            component: () => import("./children/forms/FormGroups.vue"),
          },
        ],
      },
      {
        path: "buchung",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "reservation" },
        children: [
          {
            name: "ChildCreateFormReservation",
            path: "",
            component: () => import("./children/forms/FormReservation.vue"),
          },
        ],
      },
      {
        path: "gebuehren",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "fee" },
        children: [
          {
            name: "ChildCreateFormFee",
            path: "",
            component: () => import("./children/forms/FormFee.vue"),
          },
        ],
      },
      {
        path: "vertrag",
        component: () => import("./children/views/ChildDraftForm.vue"),
        props: { step: "contract" },
        children: [
          {
            name: "ChildCreatePrintContract",
            path: "",
            component: () => import("./children/forms/PrintContract.vue"),
          },
        ],
      },
      {
        name: "ChildCreateConclusion",
        path: "abschluss",
        component: () => import("./children/views/ChildCreateConclusion.vue"),
      },
    ],
  },
  {
    name: "ChildEdit",
    path: "/kinder/:id",
    redirect: (to) => ({ name: "ChildEditFormGeneral", params: { id: to.params.id } }),
    component: () => import("./children/views/ChildView.vue"),
    meta: {
      requiredRoles: <Permission[]>["child:view"],
    },
    children: [
      {
        name: "ChildEditFormGeneral",
        path: "allgemein",
        component: () => import("./children/forms/FormGeneral.vue"),
      },
      {
        name: "ChildEditFormMiscellaneous",
        path: "verschiedenes",
        component: () => import("./children/forms/FormMiscellaneous.vue"),
      },
      {
        name: "ChildEditFormVaccination",
        path: "impfstatus",
        component: () => import("./children/forms/FormVaccination.vue"),
      },
      {
        name: "ChildEditFormLocalGuardianCreate",
        path: "bezugsperson/erstellen",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
      },
      {
        name: "ChildEditFormLocalGuardianEdit",
        path: "bezugsperson/:bid",
        component: () => import("./children/forms/FormLocalGuardian.vue"),
      },
      {
        name: "ChildEditFormGroups",
        path: "gruppen",
        component: () => import("./children/forms/FormGroups.vue"),
      },
      {
        name: "ChildEditFormReservation",
        path: "buchung",
        component: () => import("./children/forms/FormReservation.vue"),
      },
      {
        name: "ChildEditFormFee",
        path: "gebuehren",
        component: () => import("./children/forms/FormFee.vue"),
      },
      {
        name: "ChildEditPrintContract",
        path: "vertrag-drucken",
        component: () => import("./children/forms/PrintContract.vue"),
      },
    ],
  },
  {
    path: "/kinder-ansicht",
    name: "ChildrenListView",
    component: () => import("./children/views/ChildrenListView.vue"),
  },
  {
    path: "/gruppen",
    name: "GroupPage",
    component: () => import("./groups/GroupPage.vue"),
  },
]

export default routes
