import { type FetchResult } from "@apollo/client"
import { useToast } from "primevue/usetoast"

import { i18n } from "@/i18n"

export function useHandleMutationErrors() {
  const toast = useToast()

  const errorDetailToMessageMap: Record<string, string> = {
    username: i18n.global.t("admin.benutzer.error.username_exists"),
    email: i18n.global.t("admin.benutzer.error.email_exists"),
  }

  function handleMutationErrors<TResult>(result: FetchResult<TResult> | null) {
    if ((result?.errors?.length || 0) === 0) return false

    for (const error of result?.errors || []) {
      if (Array.isArray(error?.extensions?.details)) {
        error?.extensions?.details.forEach((errorType) => {
          console.error(error)
          const detail = errorDetailToMessageMap[errorType]
          toast.add({
            severity: "error",
            summary: i18n.global.t("text.error"),
            detail,
            life: 3000,
          })
        })
      } else if (typeof error?.extensions?.details === "string") {
        console.error(error)
        toast.add({
          severity: "error",
          summary: i18n.global.t("text.error"),
          detail: error.message,
          life: 3000,
        })
      } else {
        console.error(error)
        toast.add({
          severity: "error",
          summary: i18n.global.t("text.error"),
          life: 3000,
        })
      }
    }

    return true
  }
  return {
    handleMutationErrors,
  }
}
