import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import AutoComplete from "primevue/autocomplete"
import Badge from "primevue/badge"
import Button from "primevue/button"
import Card from "primevue/card"
import Chart from "primevue/chart"
import Checkbox from "primevue/checkbox"
import Chip from "primevue/chip"
import Column from "primevue/column"
import ColumnGroup from "primevue/columngroup"
import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Divider from "primevue/divider"
import Dropdown from "primevue/dropdown"
import FileUpload from "primevue/fileupload"
import IconField from "primevue/iconfield"
import InlineMessage from "primevue/inlinemessage"
import InputIcon from "primevue/inputicon"
import InputNumber from "primevue/inputnumber"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import Listbox from "primevue/listbox"
import MultiSelect from "primevue/multiselect"
import Panel from "primevue/panel"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import ProgressSpinner from "primevue/progressspinner"
import RadioButton from "primevue/radiobutton"
import Ripple from "primevue/ripple"
import Row from "primevue/row"
import Steps from "primevue/steps"
import TabPanel from "primevue/tabpanel"
import TabView from "primevue/tabview"
import Tag from "primevue/tag"
import Textarea from "primevue/textarea"
import TieredMenu from "primevue/tieredmenu"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import Toolbar from "primevue/toolbar"
import Tooltip from "primevue/tooltip"
import TriStateCheckbox from "primevue/tristatecheckbox"
import { VueDraggableNext } from "vue-draggable-next"

import type { App } from "@vue/runtime-core"

export default (app: App): void => {
  app.component("Button", Button)
  app.component("Toast", Toast)
  app.component("InputText", InputText)
  app.component("RadioButton", RadioButton)
  app.component("InputSwitch", InputSwitch)
  app.component("Badge", Badge)
  app.component("ProgressSpinner", ProgressSpinner)
  app.component("DataTable", DataTable)
  app.component("Row", Row)
  app.component("Column", Column)
  app.component("ColumnGroup", ColumnGroup)
  app.component("Toolbar", Toolbar)
  app.component("Dialog", Dialog)
  app.component("Divider", Divider)
  app.component("AutoComplete", AutoComplete)
  app.component("Dropdown", Dropdown)
  app.component("FileUpload", FileUpload)
  app.component("InputNumber", InputNumber)
  app.component("Textarea", Textarea)
  app.component("Listbox", Listbox)
  app.component("MultiSelect", MultiSelect)
  app.component("Card", Card)
  app.component("Chart", Chart)
  app.component("Checkbox", Checkbox)
  app.component("ProgressBar", ProgressBar)
  app.component("Tag", Tag)
  app.component("InlineMessage", InlineMessage)
  app.component("Draggable", VueDraggableNext)
  app.component("Chip", Chip)
  app.component("Steps", Steps)
  app.component("Password", Password)
  app.component("Accordion", Accordion)
  app.component("AccordionTab", AccordionTab)
  app.component("Panel", Panel)
  app.component("TabView", TabView)
  app.component("TabPanel", TabPanel)
  app.component("TriStateCheckbox", TriStateCheckbox)
  app.component("TieredMenu", TieredMenu)
  app.component("IconField", IconField)
  app.component("InputIcon", InputIcon)

  app.directive("ripple", Ripple)
  app.directive("tooltip", Tooltip)
  app.use(ToastService)
}
