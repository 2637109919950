import { defineStore } from "pinia"

export type Config = {
  keycloakUrl: string
  keycloakClientId: string
  keycloakRealm: string
}

export const useConfigStore = defineStore("config", () => {
  let config: Promise<Config> | null = null
  function load(): Promise<Config> {
    if (config === null) {
      config = fetch("/.well-known/config.json")
        .then((r) => r.json())
        .catch((e) => {
          console.error(e)
          return { url: "" }
        })
      return config
    } else {
      return config
    }
  }
  return {
    load,
  }
})
