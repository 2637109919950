import { useMutation, type MutateResult } from "@vue/apollo-composable"
import { merge } from "lodash"
import { computed, reactive, ref, watch } from "vue"
import { useRoute } from "vue-router"

import { useApi } from "./apollo/useApi"

import type { DataTableFilterMeta } from "primevue/datatable/DataTable"

import CreateTablePresetMutation from "@/graphql/preset/CreateTablePreset.gql"
import RemoveTablePresetsMutation from "@/graphql/preset/RemoveTablePresets.gql"
import SetDefaultTablePresetMutation from "@/graphql/preset/SetDefaultTablePreset.gql"
import TablePresetListQuery from "@/graphql/preset/TablePresetList.gql"
import UnsetDefaultTablePresetMutation from "@/graphql/preset/UnsetDefaultTablePreset.gql"
import UpdateTablePresetMutation from "@/graphql/preset/UpdateTablePreset.gql"
import {
  type MutationRootCreateTablePresetArgs,
  type MutationRootRemoveTablePresetsArgs,
  type MutationRootSetDefaultPresetArgs,
  type MutationRootUnsetDefaultPresetArgs,
  type MutationRootUpdateTablePresetArgs,
  type QueryRootTablePresetsArgs,
  type Table,
  type TablePreset,
  type TablePresetList,
} from "@/graphql/types"
import { useSessionStore } from "@/store/session"
import { type Column, type FilterableColumn, type Preset } from "@/types"
import { buildNestedObject, generateGQL, objectToGQLString } from "@/utils/gqlbuilder"

export type TablePresetConfiguration = ReturnType<typeof useTablePresetConfiguration>

export type TablePresetListResult = { tablePresets: TablePresetList }
export type TablePresetCreateResult = { createTablePreset: TablePreset }
export type TablePresetUpdateResult = { updateTablePreset: TablePreset }
export type TablePresetRemoveResult = { removeTablePresets: number }
export type SetDefaultTablePresetResult = { setDefaultPreset: boolean }
export type UnsetDefaultTablePresetResult = { unsetDefaultPreset: boolean }

export const tablePresetResultMap = {
  getList: (result: TablePresetListResult) => result.tablePresets,
  getCreated: (result: TablePresetCreateResult) => result.createTablePreset,
  getUpdated: (result: TablePresetUpdateResult) => result.updateTablePreset,
  getRemovedCount: (result: TablePresetRemoveResult) => result.removeTablePresets,
  getLinkedCount: undefined,
}

export function useTablePresetConfiguration(
  table: Table,
  columnDefs: Column[],
  listRouteName: string
) {
  const route = useRoute()
  const sessionStore = useSessionStore()

  const listQueryVariables = computed(() => ({ table }))

  const listQueryOptions = computed(() => ({
    enabled: route.name === listRouteName,
  }))

  const api = useApi<
    TablePreset,
    "tablePresets",
    TablePresetListResult,
    QueryRootTablePresetsArgs,
    undefined,
    {},
    TablePresetCreateResult,
    MutationRootCreateTablePresetArgs,
    TablePresetUpdateResult,
    MutationRootUpdateTablePresetArgs,
    TablePresetRemoveResult,
    MutationRootRemoveTablePresetsArgs
  >({
    operations: {
      list: TablePresetListQuery,
      getById: undefined,
      create: CreateTablePresetMutation,
      update: UpdateTablePresetMutation,
      remove: RemoveTablePresetsMutation,
      link: undefined,
    },
    resultMap: tablePresetResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
  })

  //Filter
  const filterableColumnDefs: FilterableColumn[] = [] // pass as param

  const filterableColumns = ref<FilterableColumn[]>(filterableColumnDefs)
  const filters = ref<DataTableFilterMeta>()

  const activeFilterableColumns = filterableColumnDefs.filter(
    (col: FilterableColumn) => col.active ?? true
  )

  //Filter Preset
  const filterPresetList = ref<Preset[]>([])
  const selectedFilterPreset = ref<Preset | undefined>()
  const defaultFilterPreset = ref("")

  //Columns
  const columns = computed(() =>
    columnDefs.filter((c) => !c.requiredPermission || sessionStore.hasRoles([c.requiredPermission]))
  )
  const selectedColumnFields = ref<{ field: string; mappedField?: string }[]>([])
  const selectedColumns = computed({
    get: () =>
      selectedColumnFields.value
        .map(
          (f) =>
            columns.value.find(
              (c) => c.field === f.field && c.mappedField === f.mappedField
            ) as Column
        )
        .filter((c) => !!c && (!!c.field || !!c.mappedField)),

    set: (columns) => {
      selectedColumnFields.value = columns.map((c) => ({
        field: c.field,
        mappedField: c.mappedField,
      }))
    },
  })

  const activeInitialColumns = columnDefs.filter((col: Column) => col.active ?? true)
  selectedColumns.value = activeInitialColumns

  const initialColumnPresetsLoaded = ref(false)
  api.whenListResultAvailable.then(() => (initialColumnPresetsLoaded.value = true))

  //Columns Preset

  const columnsPresets = computed<TablePreset[]>(() => api.listResult?.tablePresets.items || [])

  const selectedColumnsPresetId = ref<string | undefined>()
  const selectedColumnsPreset = computed({
    get: () => columnsPresets.value.find((p) => p.id === selectedColumnsPresetId.value),
    set: (preset) => (selectedColumnsPresetId.value = preset?.id),
  })

  let initialDefaultSet = false
  watch(
    columnsPresets,
    () => {
      if (
        selectedColumnsPresetId.value &&
        !columnsPresets.value?.find((p) => p.id === selectedColumnsPresetId.value)
      )
        selectedColumnsPresetId.value = undefined

      if ((columnsPresets.value ?? []).length === 0 || initialDefaultSet) return

      const defaultColumnsPreset =
        columnsPresets.value.find((p) => p.isUserDefault) ??
        columnsPresets.value.find((p) => p.isGlobalDefault)

      if (defaultColumnsPreset) selectedColumnsPreset.value = defaultColumnsPreset

      initialDefaultSet = true
    },
    { immediate: true }
  )

  watch(
    selectedColumnsPreset,
    (preset) => {
      selectedColumnFields.value = preset
        ? [
            ...preset.columns.flatMap((s) => {
              const col = columnDefs.find((y) => y.field === s || y.mappedField === s)
              if (!col) return []
              return { field: col.field, mappedField: col.mappedField }
            }),
          ]
        : activeInitialColumns.map((c) => ({ field: c.field, mappedField: c.mappedField }))
    },
    { immediate: true }
  )

  const { mutate: setDefaultPreset, loading: setDefaultLoading } = useMutation<
    SetDefaultTablePresetResult,
    MutationRootSetDefaultPresetArgs
  >(SetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareCacheReducer((cachedQuery, _data, variables) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = item.id === variables.id
      return cachedQuery
    }),
  })

  const { mutate: unsetDefaultPreset, loading: unsetDefaultLoading } = useMutation<
    UnsetDefaultTablePresetResult,
    MutationRootUnsetDefaultPresetArgs
  >(UnsetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareCacheReducer((cachedQuery) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = false
      return cachedQuery
    }),
  })

  api.addRemoveReducer(api.getRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  const defaultLoading = computed(() => setDefaultLoading.value || unsetDefaultLoading.value)

  async function create(name: string, columns: string[]): MutateResult<TablePresetCreateResult> {
    const result = await api.create({ name, table, columns })
    selectedColumnsPresetId.value = result?.data?.createTablePreset.id
    return result
  }

  async function setDefault(id: string): MutateResult<SetDefaultTablePresetResult> {
    return await setDefaultPreset({ table, id })
  }

  async function unsetDefault(): MutateResult<UnsetDefaultTablePresetResult> {
    return await unsetDefaultPreset({ table })
  }

  function nameExists(name: string, exclude: TablePreset[] = []) {
    const excludeIds = exclude.map((p) => p.id)
    return columnsPresets.value.some((p) => !excludeIds.includes(p.id) && p.name === name)
  }

  function toggleColumn(column: Column) {
    if (
      !selectedColumnFields.value.some(
        (item) => item.field === column.field && item.mappedField === column.mappedField
      )
    )
      selectedColumnFields.value.push({ field: column.field, mappedField: column.mappedField })
    else if (
      selectedColumnFields.value.filter((f) =>
        columns.value
          .map((c) => ({ field: c.field, mappedField: c.mappedField }))
          .some((item) => item.field === f.field && item.mappedField === f.mappedField)
      ).length > 1
    )
      selectedColumnFields.value = selectedColumnFields.value.filter((c) =>
        c.field !== "" ? c.field !== column.field : c.mappedField !== column.mappedField
      )
  }

  function setSelectedColumns(columns: Column[]) {
    selectedColumns.value = columns
  }

  function setSelectedColumnsPreset(preset?: TablePreset) {
    selectedColumnsPreset.value = preset
  }

  function setDefaultFilterPreset(preset: string) {
    // temporary, might be removed after filter preset rework
    defaultFilterPreset.value = preset
  }

  const requestedFields = ref<string[]>([])
  const queryFields = ref<string>("")
  const crossEntityFields = computed(() =>
    columns.value.filter((c) => c.crossEntityField).map((c) => c.field)
  )

  watch(
    selectedColumnFields,
    (fields) => {
      if (!initialColumnPresetsLoaded.value) return

      const nonCrossEntityFields = fields.filter((f) => !crossEntityFields.value.includes(f.field))
      if (nonCrossEntityFields.length === 0) return

      const oldFields = buildNestedObject(requestedFields.value, true)
      const newFields = buildNestedObject(
        nonCrossEntityFields
          .filter((f) => !requestedFields.value.includes(f.field))
          .map((x) => x.field)
      )
      queryFields.value = objectToGQLString(merge(oldFields, newFields))
      requestedFields.value = [...nonCrossEntityFields.map((x) => x.field)]
    },
    { deep: true, immediate: true }
  )

  // resets the query by removing the @client directive
  function resetQuery() {
    queryFields.value = generateGQL(selectedColumnFields.value.map((x) => x.field))
    requestedFields.value = [...selectedColumnFields.value.map((x) => x.field)]
  }

  return reactive({
    table,

    filters,
    filterableColumns,
    activeFilterableColumns,
    filterPresetList,
    selectedFilterPreset,
    defaultFilterPreset,

    initialColumnPresetsLoaded,
    activeInitialColumns,
    selectedColumnFields,
    selectedColumns,
    columns,
    columnsPresets,
    selectedColumnsPresetId,
    selectedColumnsPreset,

    nameExists,
    toggleColumn,
    setSelectedColumns,
    setSelectedColumnsPreset,
    setDefaultFilterPreset,

    api,
    create,
    defaultLoading,
    setDefault,
    unsetDefault,

    queryFields,
    resetQuery,
  })
}
