import { type OperationVariables } from "@apollo/client"
import { useQuery } from "@vue/apollo-composable"
import {
  type DocumentParameter,
  type VariablesParameter,
  type OptionsParameter,
  type UseQueryReturn,
} from "@vue/apollo-composable/dist/useQuery"
import { watch } from "vue"

// This composable serves as a workaround while Apollo `useQuery` instances inside of pinia stores are currently problematic as per: https://github.com/vuejs/apollo/issues/1538
// CAUTION: Use this only in pinia stores, not on Vue components!

export function useRestartingQuery<
  TResult,
  TVariables extends OperationVariables = OperationVariables,
>(
  document: DocumentParameter<TResult, TVariables>,
  variables: VariablesParameter<TVariables>,
  options: OptionsParameter<TResult, TVariables>
): UseQueryReturn<TResult, TVariables> {
  const _query = useQuery(document, variables, options)
  watch(_query.query, (q) => !q && _query.start())
  return _query
}
