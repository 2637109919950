import { i18n as internationalization } from "@/i18n"

export function useDropdown() {
  const i18n = internationalization.global

  /**
   * Maps a list of codes to their corresponding localized name.
   * @todo There might be a more elegant solution to this problem.
   * @param I18nKey The target locale message key.
   */
  const mapI18nOptions = (I18nKey: string, prioritizedCodes: string[] = []) => {
    const keys = I18nKey.split(".")
    const messages = i18n.messages.value
    const fallbackLocale = i18n.fallbackLocale.value as keyof typeof messages

    let nestedObject: Record<string, unknown> | undefined = messages[fallbackLocale]

    if (nestedObject) {
      for (const key of keys) {
        if (nestedObject) {
          nestedObject = nestedObject[key] as Record<string, unknown> | undefined
        }
      }

      if (nestedObject) {
        const options = Object.keys(nestedObject).map((code: string) => {
          if (code.includes(".")) {
            return {
              name: i18n.t(`${I18nKey}[${code}]`),
              code,
            }
          }
          return {
            name: i18n.t(`${I18nKey}.${code}`),
            code,
          }
        })

        const prioritizedOptions = options.filter((option) =>
          prioritizedCodes.includes(option.code)
        )
        const otherOptions = options.filter((option) => !prioritizedCodes.includes(option.code))
        const sortedOtherOptions = otherOptions.sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        )
        const sortedOptions = [...prioritizedOptions, ...sortedOtherOptions]

        return sortedOptions
      }
    }

    return []
  }

  return { mapI18nOptions }
}
