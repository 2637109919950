import { i18n } from "@/i18n"
import { type TableConfig } from "@/types"

const config: TableConfig = {
  columnDefs: [
    { field: "n", header: i18n.global.t("admin.kunden.felder.id.label"), size: "id" },

    {
      field: "name",
      header: i18n.global.t("admin.kunden.felder.name.label"),
      size: "medium",
    },
    {
      field: "customerNumber",
      header: i18n.global.t("admin.kunden.felder.kundennummer.label"),
      size: "small",
      active: false,
    },
    {
      field: "address.street",
      header: i18n.global.t("admin.kunden.felder.strasse.label"),
      size: "big",
      active: false,
    },
    {
      field: "address.zipCode",
      header: i18n.global.t("admin.kunden.felder.plz.label"),
      size: "medium",
      active: false,
    },
    {
      field: "address.city",
      header: i18n.global.t("admin.kunden.felder.ort.label"),
      size: "medium",
      active: false,
    },
    {
      field: "address.district",
      mappedField: "address.districtName",
      header: i18n.global.t("admin.kunden.felder.ortsteil.label"),
      size: "medium",
      active: false,
    },
    {
      field: "address.country",
      mappedField: "address.countryName",
      header: i18n.global.t("admin.kunden.felder.land.label"),
      size: "medium",
      active: false,
    },
  ],
}

export default config
