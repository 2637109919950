import gql from "graphql-tag"
import { defineStore } from "pinia"
import { computed } from "vue"

import { useCustomerStore } from "./customer"
import { useOrganizationStore } from "./organization"
import { useSessionStore } from "./session"

import ContextInstitutionListQuery from "@/graphql/institution/ContextInstitutionList.gql"
import CreateInstitutionMutation from "@/graphql/institution/CreateInstitution.gql"
import InstitutionByIdQuery from "@/graphql/institution/InstitutionById.gql"
import RemoveInstitutionsMutation from "@/graphql/institution/RemoveInstitutions.gql"
import UpdateInstitutionMutation from "@/graphql/institution/UpdateInstitution.gql"
import {
  type ProjectInstitution,
  type ProjectInstitutionList,
  type InstitutionType,
  Table,
  type QueryRootInstitutionsArgs,
  type MutationRootCreateInstitutionArgs,
  type MutationRootUpdateInstitutionArgs,
  type MutationRootRemoveInstitutionsArgs,
  type QueryRootInstitutionByIdArgs,
  type SimpleAddress,
  type StrictInstitutionId,
} from "@/graphql/types"
import { i18n } from "@/i18n"
import { type ContactForm } from "@/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useRestartingQuery } from "@/utils/composables/apollo/useRestartingQuery"
import { useDataTableCache } from "@/utils/composables/useDataTableCache"
import { useDropdown } from "@/utils/composables/useDropdown"
import { useTablePresetConfiguration } from "@/utils/composables/useTablePresetConfiguration"
import { EMPTY_FIELD, useOnNextResult, useWhenResult } from "@/utils/misc"
import InstitutionTableConfig from "@/views/admin/institution/InstitutionTableConfig"

export type MappedProjectInstitution = ProjectInstitution & {
  institutionTypeName: string
  address: SimpleAddress & { countryName: string; districtName: string }
  communeName: string
  contactPersons: ContactForm[]
}

export type InstitutionListResult = { institutions: ProjectInstitutionList }
export type InstitutionByIdResult = { institutionById: ProjectInstitution }
export type InstitutionCreateResult = { createInstitution: ProjectInstitution }
export type InstitutionUpdateResult = { updateInstitution: ProjectInstitution }
export type InstitutionRemoveResult = { removeInstitutions: number }

export type MappedInstitution = {
  id: string
  n: number
  iid: string
  name: string
  type: InstitutionType
  street: string
  zipCode: string
  district: string
  city: string
  country: string
  additionalAddress: string[]
  institutionNumber: string
  internalInstitutionNumber: string
  clientGroupId: string
  yearOfConstruction: string
  operatingPermitYear: string
  communeName: string
  communeId: string
}

export type MappedInstitutionSection = {
  name: string
  shortname: string
  type: InstitutionType
  costCenter: string
  hexColor: string
  contactPerson: string
}

export const institutionResultMap = {
  getList: (result: InstitutionListResult) => result.institutions,
  getById: (result: InstitutionByIdResult) => result.institutionById,
  getCreated: (result: InstitutionCreateResult) => result.createInstitution,
  getUpdated: (result: InstitutionUpdateResult) => result.updateInstitution,
  getRemovedCount: (result: InstitutionRemoveResult) => result.removeInstitutions,
  getLinkedCount: undefined,
}
export type listVariablesType = QueryRootInstitutionsArgs & {
  includeCustomer: boolean
  includeOrganization: boolean
  includeCommune: boolean
}

export const useInstitutionStore = defineStore(Table.Institutions, () => {
  const { mapI18nOptions } = useDropdown()
  const sessionStore = useSessionStore()
  const customerStore = useCustomerStore()
  const organizationStore = useOrganizationStore()

  const { result: contextInstitutionsResult } = useRestartingQuery<InstitutionListResult>(
    ContextInstitutionListQuery,
    () => ({
      // context is required for any new datatable entries to also be cached for this query
      context: sessionStore.customerFilter,
      includeCustomer: true,
      includeOrganization: true,
    }),
    () => ({
      enabled: !!sessionStore.contextCustomerId && !!sessionStore.hasRoles?.(["institution:list"]),
    })
  )

  const onFirstResult = useWhenResult(contextInstitutionsResult)
  const onNextResult = () => useOnNextResult(contextInstitutionsResult)
  const contextInstitutionList = computed(
    () => contextInstitutionsResult.value?.institutions.items ?? []
  )

  const presetConf = useTablePresetConfiguration(
    Table.Institutions,
    InstitutionTableConfig.columnDefs,
    "InstitutionPage"
  )

  const listQueryVariables = computed<listVariablesType>(() => ({
    context: sessionStore.customerFilter,
    includeCustomer: sessionStore.hasRoles?.(["customer:view"]),
    includeOrganization: sessionStore.hasRoles?.(["organization:view"]),
    includeCommune: true,
  }))
  const listQueryOptions = computed(() => ({
    enabled:
      sessionStore.hasRoles?.(["institution:list"]) && !!presetConf.initialColumnPresetsLoaded,
  }))

  const PresetBuiltQuery = computed(
    () => gql`
      query InstitutionList($context: ContextFilter, $includeCustomer: Boolean!, $includeOrganization: Boolean!, $includeCommune: Boolean!) {
        institutions(context: $context) {
          page
          limit
          total
          items {
            id
            iid
            ${presetConf.queryFields}
            customer @include(if: $includeCustomer) {
              id
              cid
            }
            organization @include(if: $includeOrganization) {
              id
              oid
            }
            commune @include(if: $includeCommune) {
              id
              name
            }
          }
        }
      }
    `
  )

  const api = useApi<
    ProjectInstitution,
    "institutions",
    InstitutionListResult,
    QueryRootInstitutionsArgs,
    InstitutionByIdResult,
    QueryRootInstitutionByIdArgs,
    InstitutionCreateResult,
    MutationRootCreateInstitutionArgs,
    InstitutionUpdateResult,
    MutationRootUpdateInstitutionArgs,
    InstitutionRemoveResult,
    MutationRootRemoveInstitutionsArgs
  >({
    operations: {
      list: PresetBuiltQuery,
      getById: InstitutionByIdQuery,
      create: CreateInstitutionMutation,
      update: UpdateInstitutionMutation,
      remove: RemoveInstitutionsMutation,
      link: undefined,
    },
    resultMap: institutionResultMap,
    mapRemovedIds: (variables) => variables.ids.map((id) => id.iid),
    listQueryVariables,
    listQueryOptions,
  })

  const toMapped = (institution: ProjectInstitution): MappedProjectInstitution => ({
    ...institution,
    communeId: institution.commune?.id ?? EMPTY_FIELD,
    communeName: institution.commune?.name ?? EMPTY_FIELD,
    institutionTypeName:
      mapI18nOptions("institution_type").find((key) => institution.type === key.code)?.name ??
      EMPTY_FIELD,
    address: {
      ...institution.address,
      countryName: institution.address?.country
        ? i18n.global.t(`countries_code.${institution.address?.country}`)
        : EMPTY_FIELD,
      districtName:
        mapI18nOptions("regions").find((key) => institution.address?.district === key.code)?.name ??
        EMPTY_FIELD,
    },
    additionalAddress: institution.additionalAddress ?? [],
    contactPersons: (institution?.contactPersons ?? []).map((c) => ({
      id: c.name,
      name: c.name,
      type: c.type ?? "",
      phone: c.contact?.phone ?? "",
      email: c.contact?.email ?? "",
      street: c.address?.street ?? "",
      zipCode: c.address?.zipCode ?? "",
      city: c.address?.city ?? "",
      district: c.address?.district ?? "",
      country: c.address?.country ?? "",
    })),
  })

  const list = computed<MappedProjectInstitution[]>(() =>
    (api.listResult?.institutions.items ?? []).map(toMapped)
  )
  const dataTableCache = useDataTableCache(list)

  customerStore.api.addRemoveReducer(
    api.getRemoveReducer((ids) => (item) => !ids.includes(item.customer?.cid))
  )
  organizationStore.api.addRemoveReducer(
    api.getRemoveReducer((ids) => (item) => !ids.includes(item.organization?.oid))
  )
  api.addRemoveReducer(api.getRemoveReducer((ids) => (item) => !ids.includes(item.iid)))

  function mapId(item: MappedProjectInstitution): StrictInstitutionId {
    return {
      cid: item.customer?.cid,
      oid: item.organization?.oid,
      iid: item.iid,
    }
  }

  return {
    api,
    list,
    dataTableCache,
    toMapped,
    mapId,

    onNextResult,
    onFirstResult,
    contextInstitutionsResult,
    contextInstitutionList,

    presetConf,
  }
})
