import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/adressen",
    name: "AddressPage",
    component: () => import("./address/AddressPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["address:list"],
    },
    children: [
      {
        path: "gemeinden/:id",
        name: "AddressCommuneDialogEdit",
        component: () => import("./address/crossEntityDialogs/CommuneDialog.vue"),
        props: { listRouteName: "AddressPage", readOnly: true },
        meta: {
          requiredRoles: <Permission[]>["commune:view"],
        },
      },
      {
        path: "schulen/:id",
        name: "AddressSchoolDialogEdit",
        component: () => import("./address/crossEntityDialogs/SchoolDialog.vue"),
        props: { listRouteName: "AddressPage", readOnly: true },
        meta: {
          requiredRoles: <Permission[]>["school:view"],
        },
      },
      {
        path: "jugendaemter/:id",
        name: "AddressYouthWelfareOfficeDialogEdit",
        component: () => import("./address/crossEntityDialogs/YouthWelfareOfficeDialog.vue"),
        props: { listRouteName: "AddressPage", readOnly: true },
        meta: {
          requiredRoles: <Permission[]>["youth_welfare_office:view"],
        },
      },
      {
        path: "ansprechpartner/:id",
        name: "AddressContactPersonDialogEdit",
        component: () => import("./address/crossEntityDialogs/ContactDialog.vue"),
        props: { listRouteName: "AddressPage", readOnly: true },
        meta: {
          requiredRoles: <Permission[]>["institution:view"],
        },
      },
    ],
  },
  {
    path: "/adressen/:id",
    name: "AddressViewEdit",
    component: () => import("./address/AddressView.vue"),
    meta: {
      requiredRoles: <Permission[]>["address:view"],
    },
    children: [
      {
        path: "",
        name: "AddressFormEdit",
        component: () => import("./address/AddressForm.vue"),
        children: [
          {
            path: "kategorie",
            name: "AddressFormEditCategoryDialog",
            props: { listRouteName: "AddressFormEdit" },
            component: () => import("./address/CreateCategoryDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["category:create"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/adressen/erstellen",
    name: "AddressViewCreate",
    component: () => import("./address/AddressView.vue"),
    meta: {
      requiredRoles: <Permission[]>["address:view"],
    },
    children: [
      {
        path: "",
        name: "AddressFormCreate",
        component: () => import("./address/AddressForm.vue"),
        children: [
          {
            path: "kategorie",
            name: "AddressFormCreateCategoryDialog",
            props: { listRouteName: "AddressFormCreate" },
            component: () => import("./address/CreateCategoryDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["category:create"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/gemeinden",
    name: "CommunePage",
    component: () => import("./commune/CommunePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["commune:list"],
    },
    children: [
      {
        path: ":id",
        name: "CommuneDialogEdit",
        component: () => import("./commune/CommuneDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["commune:view"],
        },
      },
      {
        path: "erstellen",
        name: "CommuneDialogCreate",
        component: () => import("./commune/CommuneDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["commune:create"],
        },
      },
    ],
  },
  {
    path: "/jugendaemter",
    name: "YouthWelfareOfficePage",
    component: () => import("./ywoffice/YouthWelfareOfficePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["youth_welfare_office:list"],
    },
    children: [
      {
        path: ":id",
        name: "YouthWelfareOfficeDialogEdit",
        component: () => import("./ywoffice/YouthWelfareOfficeDialog.vue"),
      },
      {
        path: "erstellen",
        name: "YouthWelfareOfficeDialogCreate",
        component: () => import("./ywoffice/YouthWelfareOfficeDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["youth_welfare_office:create"],
        },
      },
    ],
  },
  {
    path: "/schulen",
    name: "SchoolPage",
    component: () => import("./school/SchoolPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["school:list"],
    },
    children: [
      {
        path: ":id",
        name: "SchoolDialogEdit",
        component: () => import("./school/SchoolDialog.vue"),
      },
      {
        path: "erstellen",
        name: "SchoolDialogCreate",
        component: () => import("./school/SchoolDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["school:create"],
        },
      },
    ],
  },
  {
    path: "/zusatzfunktionen-qualifikationen",
    name: "QualificationPage",
    component: () => import("./qualification/QualificationPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["qualification:list"],
    },
    children: [
      {
        path: ":id",
        name: "QualificationDialogEdit",
        component: () => import("./qualification/QualificationDialog.vue"),
      },
      {
        path: "erstellen",
        name: "QualificationDialogCreate",
        component: () => import("./qualification/QualificationDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["qualification:create"],
        },
      },
    ],
  },
  {
    path: "/essensarten",
    name: "FoodTypePage",
    component: () => import("./foodType/FoodTypePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["food_type:list"],
    },
    children: [
      {
        path: ":id",
        name: "FoodTypeDialogEdit",
        component: () => import("./foodType/FoodTypeDialog.vue"),
      },
      {
        path: "erstellen",
        name: "FoodTypeDialogCreate",
        component: () => import("./foodType/FoodTypeDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["food_type:create"],
        },
      },
    ],
  },
  {
    path: "/dienstarten",
    name: "ServiceTypePage",
    component: () => import("./serviceType/ServiceTypePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["service_type:list"],
    },
    children: [
      {
        path: ":id",
        name: "ServiceTypeDialogEdit",
        component: () => import("./serviceType/ServiceTypeDialog.vue"),
      },
      {
        path: "erstellen",
        name: "ServiceTypeDialogCreate",
        component: () => import("./serviceType/ServiceTypeDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["service_type:create"],
        },
      },
    ],
  },
  {
    path: "/kategorien",
    name: "CategoryPage",
    component: () => import("./categories/CategoryPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["category:list"],
    },
    children: [
      {
        path: ":id",
        name: "CategoryDialogEdit",
        component: () => import("./categories/CategoryDialog.vue"),
      },
      {
        path: "erstellen",
        name: "CategoryDialogCreate",
        component: () => import("./categories/CategoryDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["category:create"],
        },
      },
    ],
  },
]

export default routes
